import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Body2 } from '../../../../components/Typography';

import './index.scss';

const HEADER_HEIGHT = 64;

const Link = ({
  sectionsId,
  textId,
  isActive,
  clickedSectionId,
  setClickedSectionId,
  scrollingDisabled,
  setScrollingDisabled
}) => {
  const linkRef = useRef(null);

  const handleOnClick = () => {
    setClickedSectionId(sectionsId);
    setScrollingDisabled(true);

    const element = document.getElementById(sectionsId);

    if (element) {
      const parentElement = element.parentElement?.parentElement?.parentElement;
      if (parentElement) {
        const elementOffset = element.offsetTop;
        const parentOffset = parentElement.offsetTop;
        const scrollYPosition = elementOffset - parentOffset;

        parentElement.scrollTo({
          top: scrollYPosition - HEADER_HEIGHT,
          behavior: 'smooth'
        });
      }
    }

    setTimeout(() => {
      adjustHorizontalScroll();
      setTimeout(() => setScrollingDisabled(false), 500);
    }, 500);
  };

  const adjustHorizontalScroll = () => {
    if (linkRef.current) {
      const scrollableParent = linkRef.current.parentElement;

      if (scrollableParent) {
        let leftSpacing = 24;
        const linkPosition = linkRef.current.offsetLeft;
        const parentPosition = scrollableParent.offsetLeft;
        scrollableParent.scrollTo({
          left: linkPosition - parentPosition - leftSpacing,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    if (isActive && (!scrollingDisabled || clickedSectionId === sectionsId)) {
      adjustHorizontalScroll();
    }
  }, [isActive, clickedSectionId, scrollingDisabled, sectionsId]);

  return (
    <button
      ref={linkRef}
      className={cx({
        link: true,
        'link--active': isActive
      })}
      onClick={handleOnClick}
    >
      <Body2 className='link__text'>
        <FormattedMessage id={textId} />
      </Body2>
    </button>
  );
};

export default Link;

Link.propTypes = {
  sectionsId: PropTypes.string,
  textId: PropTypes.node,
  isActive: PropTypes.bool,
  clickedSectionId: PropTypes.string,
  setClickedSectionId: PropTypes.func,
  scrollingDisabled: PropTypes.bool,
  setScrollingDisabled: PropTypes.func
};
