import { takeLatest, call, put } from 'redux-saga/effects';

import {
  UPDATE_DASHBOARD_DATA_START,
  UPDATE_DASHBOARD_DATA_SUCCESS,
  UPDATE_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  CREATE_TEST_ORDER_START,
  CREATE_TEST_ORDER_SUCCESS,
  CREATE_TEST_ORDER_ERROR,
  SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS,
  SAVE_CHECKUP_RECOMMENDATION_ID_ERROR,
  SAVE_CHECKUP_RECOMMENDATION_ID_START,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR
} from '../actions/dashboard';
import api from '../api';

function createGetDashboardData() {
  return function* (options) {
    // eslint-disable-line consistent-return
    const { language } = options.payload;
    try {
      const data = yield call(() => api.getDashboardData(language));
      const action = { type: GET_DASHBOARD_DATA_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: GET_DASHBOARD_DATA_ERROR, error };

      yield put(action);
    }
  };
}

function* getDashboardDataWatcher() {
  yield takeLatest(GET_DASHBOARD_DATA_START, createGetDashboardData());
}

function createUpdateDashboard() {
  return function* (options) {
    // eslint-disable-line consistent-return
    const { language } = options.payload;
    try {
      const data = yield call(() => api.updateDashboard(language));
      const action = { type: UPDATE_DASHBOARD_DATA_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: UPDATE_DASHBOARD_DATA_ERROR, error };

      yield put(action);
    }
  };
}

function* updateDashboardWatcher() {
  yield takeLatest(UPDATE_DASHBOARD_DATA_START, createUpdateDashboard());
}

function createTestOrder() {
  return function* (option) {
    const { orderData } = option.payload;
    // eslint-disable-line consistent-return
    try {
      const data = yield call(() => api.createOrder(orderData));
      const action = { type: CREATE_TEST_ORDER_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: CREATE_TEST_ORDER_ERROR, error };

      yield put(action);
    }
  };
}

function* createTestOrderWatcher() {
  yield takeLatest(CREATE_TEST_ORDER_START, createTestOrder());
}

function createSaveCheckUpRecommendationId() {
  return function* (option) {
    const { checkUpRecommendationId } = option.payload;
    try {
      const data = yield call(() =>
        api.saveCheckUpRecommendationId(checkUpRecommendationId)
      );
      const action = { type: SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: SAVE_CHECKUP_RECOMMENDATION_ID_ERROR, error };

      yield put(action);
    }
  };
}

function* saveCheckUpRecommendationIdWatcher() {
  yield takeLatest(
    SAVE_CHECKUP_RECOMMENDATION_ID_START,
    createSaveCheckUpRecommendationId()
  );
}

function createToggleCheckupCardCompletionStatus() {
  return function* (option) {
    const { checkUpRecommendationId, key } = option.payload;
    try {
      const data = yield call(() =>
        api.toggleCheckupCardCompletionStatus(checkUpRecommendationId, key)
      );
      const action = { type: TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS, data };

      yield put(action);
    } catch (error) {
      const action = { type: TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR, error };

      yield put(action);
    }
  };
}

function* toggleCheckupCardCompletionStatusWatcher() {
  yield takeLatest(
    TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START,
    createToggleCheckupCardCompletionStatus()
  );
}

export default [
  updateDashboardWatcher(),
  getDashboardDataWatcher(),
  createTestOrderWatcher(),
  saveCheckUpRecommendationIdWatcher(),
  toggleCheckupCardCompletionStatusWatcher()
];
