import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle2, Overline } from '../../../../components/Typography';

import './index.scss';

const RecommendedActionsTiming = ({ timing, actions }) => {
  return (
    <>
      {timing && (
        <div className='recommended-actions-timing'>
          <div className='recommended-actions-timing__circle'>
            <div className='recommended-actions-timing__line'></div>
          </div>

          <div className='recommended-actions-timing__text-wrapper'>
            <Subtitle2 className='recommended-actions-timing__text'>
              {timing}
            </Subtitle2>
            <Overline
              className='recommended-actions-timing__number'
              element='span'
            >
              {actions.length}
            </Overline>
          </div>
        </div>
      )}
    </>
  );
};

RecommendedActionsTiming.propTypes = {
  timing: PropTypes.string,
  actions: PropTypes.array
};

export default RecommendedActionsTiming;
