import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CheckupAccordionProductCard from '../CheckupAccordionProductCard';
import CheckupAccordionSmallBanner from '../CheckupAccordionSmallBanner';
import CheckupAccordionAddToCartButton from '../CheckupAccordionAddToCartButton';
import CheckupAccordionDescription from '../CheckupAccordionDescription';

import './index.scss';

const CheckupAccordionContent = ({
  description,
  descriptionURL,
  products,
  cta
}) => {
  const getListOfVariantIds = products =>
    products ? products.map(product => product.variantId) : [];

  const [selectedProductsVariantIds, setSelectedProductsVariantIds] = useState(
    getListOfVariantIds(products)
  );

  const handleProductToggle = variantId => {
    setSelectedProductsVariantIds(prevIds =>
      prevIds.includes(variantId)
        ? prevIds.filter(id => id !== variantId)
        : [...prevIds, variantId]
    );
  };

  return (
    <div
      className={cx({
        'checkup-accordion-content': true,
        'checkup-accordion-content--no-padding': cta
      })}
    >
      <div className={cx({ 'checkup-accordion-content__description': cta })}>
        <CheckupAccordionDescription
          description={description}
          descriptionURL={descriptionURL}
        />
      </div>

      {products && products.length > 0 && (
        <>
          <div className='checkup-accordion-content__product-cards'>
            {products.map(product => (
              <CheckupAccordionProductCard
                className='checkup-accordion-content__product-card'
                key={product.type}
                description={product.description}
                link={product.link}
                tags={product.tags}
                name={product.name}
                price={product.price}
                image={product.image}
                variantId={product.variantId}
                isChecked={selectedProductsVariantIds.includes(
                  product.variantId
                )}
                onToggle={handleProductToggle}
              />
            ))}
          </div>
          <div className='checkup-accordion-content__add-to-cart-button-wrapper'>
            <CheckupAccordionAddToCartButton
              selectedProducts={selectedProductsVariantIds}
            />
          </div>
        </>
      )}

      {cta && (
        <div className='checkup-accordion-content__small-banner'>
          <CheckupAccordionSmallBanner data={cta} />
        </div>
      )}
    </div>
  );
};

CheckupAccordionContent.propTypes = {
  description: PropTypes.string,
  descriptionURL: PropTypes.string,
  products: PropTypes.array,
  cta: PropTypes.object
};

export default CheckupAccordionContent;
