export const UPDATE_DASHBOARD_DATA_START = 'UPDATE_DASHBOARD_DATA_START';
export const UPDATE_DASHBOARD_DATA_SUCCESS = 'UPDATE_DASHBOARD_DATA_SUCCESS';
export const UPDATE_DASHBOARD_DATA_ERROR = 'UPDATE_DASHBOARD_DATA_ERROR';
export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const CREATE_TEST_ORDER_START = 'CREATE_TEST_ORDER_START';
export const CREATE_TEST_ORDER_SUCCESS = 'CREATE_TEST_ORDER_SUCCESS';
export const CREATE_TEST_ORDER_ERROR = 'CREATE_TEST_ORDER_ERROR';
export const RESET_TEST_ORDER_DATA = 'RESET_TEST_ORDER_DATA';
export const SAVE_CHECKUP_RECOMMENDATION_ID_START =
  'SAVE_CHECKUP_RECOMMENDATION_ID_START';
export const SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS =
  'SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS';
export const SAVE_CHECKUP_RECOMMENDATION_ID_ERROR =
  'SAVE_CHECKUP_RECOMMENDATION_ID_ERROR';
export const TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START =
  'TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START';
export const TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS =
  'TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS';
export const TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR =
  'TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR';
export const RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS = 'RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS';

export const updateDashboardData = language => ({
  type: UPDATE_DASHBOARD_DATA_START,
  payload: {
    language
  }
});

export const getDashboardData = language => ({
  type: GET_DASHBOARD_DATA_START,
  payload: {
    language
  }
});

export const createTestOrder = orderData => ({
  type: CREATE_TEST_ORDER_START,
  payload: {
    orderData
  }
});

export const resetTestOrderData = () => ({
  type: RESET_TEST_ORDER_DATA
});

export const saveCheckUpRecommendationId = checkUpRecommendationId => ({
  type: SAVE_CHECKUP_RECOMMENDATION_ID_START,
  payload: {
    checkUpRecommendationId
  }
});

export const toggleCheckupCardCompletionStatus = (
  checkUpRecommendationId,
  key
) => ({
  type: TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START,
  payload: {
    checkUpRecommendationId,
    key
  }
});

export const resetMarkAsCompleteButtonSuccess = () => ({
  type: RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS
});
