import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading3 } from '../../../components/Typography';
import CheckupAccordion from '../CheckupAccordion';
import CheckupAccordionContent from '../CheckupAccordion/CheckupAccordionContent';

import './index.scss';

const CheckupAccordions = ({ title, actions }) => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const handleOpenAccordion = index => {
    setOpenAccordion(prev => (prev === index ? null : index));
  };

  return (
    <div className='checkup-accordions'>
      <Heading3 className='checkup-accordions__title'>{title}</Heading3>
      <div className='checkup-accordions__accordions'>
        {actions.map((action, index) => (
          <CheckupAccordion
            key={action.key}
            title={action.title}
            subtitle={action.subtitle}
            number={index + 1} // currently from backend I get null, when changed, update this to {nubmer}
            isOpen={openAccordion === index}
            handleOpenAccordion={() => handleOpenAccordion(index)}
          >
            <CheckupAccordionContent
              description={action.description}
              products={action.products}
              cta={action.cta}
            />
          </CheckupAccordion>
        ))}
      </div>
    </div>
  );
};

CheckupAccordions.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array
};

export default CheckupAccordions;
