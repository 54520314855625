import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Body2 } from '../../../../components/Typography';
import { ReactComponent as SuccessIcon } from '../../../../assets/svg/success-icon-with-transparent-bg.svg';

import useClickOutside from '../../../hooks/useClickOutside';

import './index.scss';

const TagsList = ({ tags }) => {
  const [visibleTags, setVisibleTags] = useState([]);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const tagsToArray = tags.split(',');
  const containerRef = useRef(null);
  const additionalRef = useRef(null);

  useClickOutside(additionalRef, () => setIsTooltipVisible(false));

  useEffect(() => {
    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      container.style.visibility = 'hidden';
      setVisibleTags(tagsToArray);
      setHiddenCount(0);

      // Force layout calculation
      void container.offsetWidth;

      setTimeout(() => {
        const containerWidth = container.offsetWidth;
        const tagElements = Array.from(
          container.querySelectorAll('.tags-list__tag')
        );

        // Always reserve space for the first tag and the +N indicator
        const firstTagWidth = tagElements[0]?.offsetWidth || 0;
        const moreTagWidth = 31; // Approximate width for the +N indicator

        // If container can't fit both first tag and +N indicator, we'll show truncated first tag
        if (
          firstTagWidth + moreTagWidth > containerWidth &&
          tagsToArray.length > 1
        ) {
          setVisibleTags([tagsToArray[0]]);
          setHiddenCount(tagsToArray.length);
        } else {
          // Calculate remaining tags that can fit
          let currentWidth = firstTagWidth + 8; // First tag width + margin
          let visibleCount = 1; // Always include first tag

          for (let i = 1; i < tagElements.length; i++) {
            currentWidth += tagElements[i].offsetWidth + 8; // Add tag width + margin

            // Reserve space for the +N indicator
            if (currentWidth + moreTagWidth > containerWidth) {
              break;
            }

            visibleCount++;
          }

          setVisibleTags(tagsToArray.slice(0, visibleCount));
          setHiddenCount(tagsToArray.length - visibleCount);
        }

        container.style.visibility = 'visible';
      }, 0);
    };

    calculateVisibleTags();
    window.addEventListener('resize', calculateVisibleTags);
    return () => window.removeEventListener('resize', calculateVisibleTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleAdditionalButtonClick = event => {
    event.preventDefault();
    setIsTooltipVisible(prev => !prev);
  };

  return (
    <div ref={containerRef} className='tags-list'>
      {visibleTags.map((tag, index) => (
        <div key={index} className='tags-list__tag'>
          <SuccessIcon className='tags-list__success-icon' />
          <Body2 className='tags-list__tag-name'>{tag}</Body2>
        </div>
      ))}
      {hiddenCount > 0 && (
        <div
          className={cx('tags-list__additional', {
            'tags-list__additional--visible': isTooltipVisible
          })}
          onClick={handleAdditionalButtonClick}
          ref={additionalRef}
        >
          <Body2 className='tags-list__number'>+{hiddenCount}</Body2>

          <div className='tags-list__tooltip'>
            {tagsToArray.slice(-hiddenCount).map((tag, index) => (
              <div
                key={index}
                className='tags-list__tag tags-list__tooltip-tag'
              >
                <SuccessIcon className='tags-list__success-icon' />
                <Body2 className='tags-list__tag-name'>{tag}</Body2>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

TagsList.propTypes = {
  tags: PropTypes.string.isRequired
};

export default TagsList;
