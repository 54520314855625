import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Subtitle1, Overline } from '../../../components/Typography';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import './index.scss';

export const LAB_TESTS_URL = 'https://yourself.health/pages/lab-tests';

const MyHealthHeaderNavbar = ({ data }) => {
  const [numberOfResultsNotViewed, setNumberOfResultsNotViewed] = useState(0);

  useEffect(() => {
    if (data && data.tests) {
      const numberOfNotViewedResults = getNumberOfResultsNotViewed(data.tests);
      setNumberOfResultsNotViewed(numberOfNotViewedResults);
    } else {
      setNumberOfResultsNotViewed(0);
    }
  }, [data]);

  const getNumberOfResultsNotViewed = tests => {
    const testsWithResultsNotViewed = tests.filter(test => {
      const { resultsViewed } = test;
      const isTestViewed =
        resultsViewed === null ||
        resultsViewed === undefined ||
        resultsViewed === true
          ? true
          : false;

      return isTestViewed === false;
    });

    return testsWithResultsNotViewed.length;
  };

  return (
    <div className='my-health-header-navbar'>
      <NavLink
        exact={true}
        to='/myhealth'
        className='my-health-header-navbar__link'
        activeClassName='my-health-header-navbar__active-link'
      >
        <Subtitle1>
          <FormattedMessage id='dashboard.home' defaultMessage='Home' />
        </Subtitle1>
      </NavLink>

      <NavLink
        exact={true}
        to='/myhealth/products'
        className='my-health-header-navbar__link'
        activeClassName='my-health-header-navbar__active-link'
      >
        <Subtitle1>
          <FormattedMessage
            id='dashboard.my-products'
            defaultMessage='My Products'
          />
        </Subtitle1>
        {numberOfResultsNotViewed > 0 && (
          <div className='my-health-header-navbar__bubble'>
            <Overline className='my-health-header-navbar__number-of-unviewed-results'>
              {numberOfResultsNotViewed}
            </Overline>
          </div>
        )}
      </NavLink>
      {data && data.employeeBenefits && (
        <NavLink
          exact={true}
          to='/myhealth/employee-health'
          className='my-health-header-navbar__link'
          activeClassName='my-health-header-navbar__active-link'
        >
          <Subtitle1>
            <FormattedMessage
              id='dashboard.employee-health'
              defaultMessage='Employee Health'
            />
          </Subtitle1>
        </NavLink>
      )}

      <NavLink
        exact={true}
        to='/myhealth/check-up'
        className='my-health-header-navbar__link'
        activeClassName='my-health-header-navbar__active-link'
      >
        <Subtitle1>
          <FormattedMessage
            id='dashboard.checkup-planner'
            defaultMessage='Check-up Planner'
          />
        </Subtitle1>
      </NavLink>

      <a
        href={LAB_TESTS_URL}
        className='my-health-header-navbar__link'
        target='_blank'
        rel='noreferrer'
      >
        <Subtitle1>
          <FormattedMessage id='dashboard.shop' defaultMessage='Shop' />
        </Subtitle1>
      </a>
    </div>
  );
};
MyHealthHeaderNavbar.propTypes = {
  data: PropTypes.object
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data')
});

export default connect(mapStateToProps)(MyHealthHeaderNavbar);
