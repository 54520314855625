import cardiovascularRiskCheck from './learnMore/cardiovascularRiskCheck.js';
import skinCheck from './learnMore/skinCheck.js';
import diabetesScreening from './learnMore/diabetesScreening.js';
import vitaminB12Tracking from './learnMore/vitaminB12Tracking.js';
import stiScreeningMale from './learnMore/stiScreeningMale.js';
import stiScreeningFemale from './learnMore/stiScreeningFemale.js';
import gynaecologyCheckUp from './learnMore/gynaecologyCheckUp.js';
import prostateCancerScreening from './learnMore/prostateCancerScreening.js';
import colonCancerScreening from './learnMore/colonCancerScreening.js';
import ironCheck from './learnMore/ironCheck.js';
import socialEggFreezing from './learnMore/socialEggFreezing.js';

export default [
  cardiovascularRiskCheck,
  skinCheck,
  diabetesScreening,
  vitaminB12Tracking,
  stiScreeningMale,
  stiScreeningFemale,
  gynaecologyCheckUp,
  prostateCancerScreening,
  colonCancerScreening,
  ironCheck,
  socialEggFreezing
];
