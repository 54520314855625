import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { dataType } from '../../constants/data';
import CircleLoader from '../../components/CircleLoader';
import { getUser } from '../../actions/user';

import Content from '../../components/Content';
import Background from '../../components/Background';

import { Redirect } from 'react-router-dom';

import {
  continueConversation,
  startConversation,
  updateConversation,
  startConversationWithCode,
  createPrescriptionConversationWithId,
  startConversationWithBenefitCode
} from '../../actions/data';
import { getDecodedUrl } from '../../utils/helpers';
import { getBrowserLanguage } from '../../utils/browsers';

import './index.scss';
import './newDesign.scss';

const getSearchParams = searchString => {
  const searchParams = new URLSearchParams(searchString.substr(1));
  // We are checking if user selected some langauge
  const currentLang = localStorage.getItem('hy-language');
  // If not, we are getting browser language
  const browserLanguage =
    currentLang && currentLang !== 'null' && currentLang !== 'undefined'
      ? currentLang
      : getBrowserLanguage();

  let conversationFlowKey = searchParams.get('conversationFlowKey');
  if (!searchParams.get('conversationFlowKey')) {
    conversationFlowKey = localStorage.getItem('conversationFlowKey');
    if (conversationFlowKey == null) {
      let flowCountry = localStorage.getItem('flowCountry');
      if (flowCountry) {
        conversationFlowKey = `stdResults.${flowCountry}`;
      } else {
        conversationFlowKey = 'stdResults';
      }
    }
  } else {
    localStorage.setItem('conversationFlowKey', conversationFlowKey);
  }

  let decodedUrl = null;
  if (searchParams.get('source')) {
    decodedUrl = getDecodedUrl(searchParams.get('source'));
  }

  return {
    lang: searchParams.get('lang') ? searchParams.get('lang') : browserLanguage,
    conversationFlowKey: conversationFlowKey,
    source: decodedUrl,
    testCode: searchParams.get('testCode'),
    prescriptionId: searchParams.get('prescriptionId'),
    benefitCode: searchParams.get('benefitCode')
  };
};

const getUserToken = () => {
  return localStorage.getItem('accessToken');
};

const handleLanguageChange = (
  dispatch,
  conversationId,
  changeLanguage
) => value => {
  changeLanguage(value);
  dispatch(updateConversation(value, conversationId));
};

const Home = ({
  dispatch,
  data,
  dataLoading,
  history,
  error,
  userData,
  userLoading,
  language,
  changeLanguage
}) => {
  let { conversationId } = useParams();
  const [hasConversationId, setHasConversationId] = useState(false);
  const [searchParams, setSearchParams] = useState(null);

  const searchString = history.location.search;

  useEffect(() => {
    const searchParams = getSearchParams(searchString);
    setSearchParams(searchParams);

    const currentLang = localStorage.getItem('hy-language');
    if (currentLang !== searchParams.lang) {
      changeLanguage(searchParams.lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentUserId = localStorage.getItem('userId');

    if (data && currentUserId !== data.userId) {
      localStorage.setItem('userId', data.userId);
    }
    if (data?.content?.kind === dataType.REDIRECT) {
      // Sometimes redirectUrl have ? already, so we just need to add &lang to the url
      const cointainsQuestionMark = data.content.redirectUrl.includes('?');
      let langPartOfUrl = '';
      if (language) {
        langPartOfUrl = cointainsQuestionMark
          ? `&lang=${language}`
          : `?lang=${language}`;
      }
      window.location.href = `${process.env.REACT_APP_SECURE_APP_URL}/${data.content.redirectUrl}${langPartOfUrl}`;
      return;
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isSecurePath =
      window.location.origin === process.env.REACT_APP_SECURE_APP_URL;

    if (searchParams) {
      const lang = searchParams.lang || language;
      if (searchParams.testCode) {
        const urlSearchParams = new URLSearchParams(history.location.search);
        const conversationFlowKey = urlSearchParams.get('conversationFlowKey');
        if (conversationFlowKey === 'switchboard' && searchParams.testCode) {
          dispatch(startConversation(searchParams.conversationFlowKey, lang));
        } else {
          dispatch(
            startConversationWithCode(
              searchParams.testCode,
              conversationFlowKey,
              lang
            )
          );
        }
      } else if (searchParams.prescriptionId) {
        localStorage.setItem('prescriptionId', searchParams.prescriptionId);
        dispatch(
          createPrescriptionConversationWithId(
            searchParams.prescriptionId,
            lang
          )
        );
      } else if (searchParams.benefitCode) {
        dispatch(
          startConversationWithBenefitCode(
            searchParams.benefitCode,
            searchParams.conversationFlowKey,
            lang
          )
        );
      } else if (!conversationId) {
        dispatch(startConversation(searchParams.conversationFlowKey, lang));
      } else {
        dispatch(continueConversation(conversationId));
      }
    }
    const token = getUserToken();
    if (
      isSecurePath &&
      (!userData ||
        (userData &&
          Object.keys(userData).length === 0 &&
          Object.getPrototypeOf(userData) === Object.prototype &&
          token))
    ) {
      dispatch(getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (data && !hasConversationId && !conversationId) {
      history.push(`/${data.conversationId}`);
      setHasConversationId(true);
    }

    if (data && data.conversationId !== conversationId) {
      history.push(`/${data.conversationId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasConversationId]);

  useEffect(() => {
    if (searchString.length > 0 && data && data.conversationId) {
      window.location.replace(searchParams.source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  // We are reloading page if user going back comes to "/"
  useEffect(() => {
    if (
      history.location.pathname === '/' &&
      !searchString &&
      hasConversationId
    ) {
      window.location.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  if (!data) {
    if (window.location.pathname === '/' && searchString === '') {
      return <Redirect to='/myhealth' />;
    }

    return (
      <Background className='home__background-wrapper'>
        <div className='home__loader-wrapper'>
          <CircleLoader />
        </div>
      </Background>
    );
  }

  // Currently we are in transition between new and old design so we will use this parameter to show the new design
  const newDesignRequested = [
    'allergyCheckRecommendation',
    'checkUpRecommendation',
    'fertilityCheckRecommendation',
    'fertilityConsultation'
  ].includes(data.conversationFlowKey);

  return (
    <div
      className={cx({
        home: true,
        'home--new-design': newDesignRequested
      })}
    >
      <div className='home__background'>
        <div className='home__elipse-wrapper'>
          <div className='home__elipse' />
        </div>
      </div>
      {data && data.content && data.content.kind !== dataType.REDIRECT ? (
        <Content
          data={data}
          dataLoading={dataLoading || userLoading}
          // dataLoading={dataLoading}
          searchParams={searchParams}
          changeLanguage={handleLanguageChange(
            dispatch,
            conversationId,
            changeLanguage
          )}
          language={language || data.lang}
          userData={userData}
          userLoading={userLoading}
        />
      ) : (
        <div className='home__loader'>loading</div>
      )}
    </div>
  );
};

Home.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  dataLoading: PropTypes.bool,
  history: PropTypes.object,
  error: PropTypes.object,
  userData: PropTypes.object,
  userLoading: PropTypes.bool,
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.data.get('data'),
  dataLoading: state.data.get('loading'),
  error: state.data.get('error'),
  userData: state.user.get('user'),
  userLoading: state.user.get('loading')
});

export default connect(mapStateToProps)(withRouter(Home));
