import React from 'react';

import BannerWithList, { ListItem } from '../../../components/BannerWithList';
import CheckupAccordionProductCard from '../../components/CheckupAccordion/CheckupAccordionProductCard';

import styles from './index.module.scss';

type ProductData = {
  type: string;
  description: string;
  link: string;
  tags: string;
  name: string;
  price: string;
  image: string;
  addToCartUrl: string;
};

interface BannerWithListAndProductCardProps {
  list: ListItem[];
  title: string;
  iconType: string;
  product: ProductData;
}

const BannerWithListAndProductCard: React.FC<BannerWithListAndProductCardProps> = ({
  list,
  title,
  iconType,
  product
}) => {
  return (
    <div className={styles.wrapper}>
      <BannerWithList
        className={styles.list}
        title={title}
        iconType={iconType}
        list={list}
      />
      <CheckupAccordionProductCard
        className={styles.product}
        key={product.type}
        description={product.description}
        link={product.link}
        tags={product.tags}
        name={product.name}
        price={product.price}
        image={product.image}
        addToCartUrl={product.addToCartUrl}
      />
    </div>
  );
};

export default BannerWithListAndProductCard;
