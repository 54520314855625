import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Subtitle2 } from '../Typography';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right-icon.svg';

import styles from './index.module.scss';

interface BannerWithTextAndButtonProps {
  title: string;
  text: string;
  imageUrl: string;
  buttonText: string;
  buttonUrl: string;
}

const BannerWithTextAndButton: React.FC<BannerWithTextAndButtonProps> = ({
  title,
  text,
  buttonText,
  imageUrl,
  buttonUrl
}) => {
  return (
    <div className={styles.bannerWithTextAndButton}>
      <img className={styles.image} src={imageUrl} alt='Banner' />
      <div className={styles.textWrapper}>
        <Subtitle2 className={styles.title}>
          <FormattedMessage id={title} />
        </Subtitle2>
        <Subtitle2 className={styles.text}>
          <FormattedMessage id={text} />
        </Subtitle2>
      </div>
      <a className={styles.buttonWrapper} href={buttonUrl}>
        <Subtitle2 className={styles.buttonText} element='span'>
          <FormattedMessage id={buttonText} />
        </Subtitle2>
        <span className={styles.iconWrapper}>
          <ArrowRight />
        </span>
      </a>
    </div>
  );
};

export default BannerWithTextAndButton;
