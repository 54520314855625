import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Body1 } from '../Typography';

import styles from './index.module.scss';

interface ListItemTextWithLinkProps {
  text: string;
  icon: JSX.Element;
  link: string;
  linkText: string;
}

const ListItemTextWithLink: React.FC<ListItemTextWithLinkProps> = ({
  text,
  icon,
  link,
  linkText
}) => {
  return (
    <div className={styles.wrapper}>
      {icon}
      <Body1 className={styles.item}>
        {
          <FormattedMessage
            id={text}
            values={{
              link: (
                <a
                  className={styles.link}
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                >
                  <FormattedMessage id={linkText} />
                </a>
              )
            }}
          />
        }
      </Body1>
    </div>
  );
};

export default ListItemTextWithLink;
