import EggFreezingHero from '../../../assets/images/social-egg-freezing-hero-section.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'social-egg-freezing',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.social-egg-freezing.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: EggFreezingHero,
      text: 'learnMoreContent.social-egg-freezing.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.social-egg-freezing.purpose.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.social-egg-freezing.purpose.listItem1', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.purpose.listItem2', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.purpose.listItem3', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.purpose.listItem4', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.social-egg-freezing.consideration.title',
      description: { text: 'learnMoreContent.social-egg-freezing.consideration.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.social-egg-freezing.howItWorks.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.social-egg-freezing.howItWorks.listItem1', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.howItWorks.listItem2', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.howItWorks.listItem3', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.howItWorks.listItem4', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.howItWorks.listItem5', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.social-egg-freezing.age.title',
      description: { text: 'learnMoreContent.social-egg-freezing.age.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.social-egg-freezing.cost.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.social-egg-freezing.cost.listItem1', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.cost.listItem2', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.cost.listItem3', type: 'text' },
        { text: 'learnMoreContent.social-egg-freezing.cost.listItem4', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.social-egg-freezing.limitation.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.social-egg-freezing.limitation.listItem1', type: 'text' },
      ]
    },
  ]
}