import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import Link from '../TestResultsNavigation/Link';

import { Typography } from '../../../components/Typography';

import './index.scss';

const FIXED_NAVIGATION_PADDING_TOP = 10;

const TestResultsNavigation = ({
  className,
  linksData,
  activeSection,
  scrollableElementRef
}) => {
  const [clickedSectionId, setClickedSectionId] = useState(null);
  const [scrollingDisabled, setScrollingDisabled] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const navigationElementRef = useRef(null);
  const navigationFixedThreshold = useRef(0);

  useEffect(() => {
    if (!scrollableElementRef?.current || !navigationElementRef.current) return;

    const scrollableElement = scrollableElementRef.current;

    const updateInitialPosition = () => {
      const scrollableRect = scrollableElement.getBoundingClientRect();
      const navigationRect = navigationElementRef.current.getBoundingClientRect();
      navigationFixedThreshold.current =
        navigationRect.top - scrollableRect.top - FIXED_NAVIGATION_PADDING_TOP;
    };

    updateInitialPosition();

    const handleScroll = () => {
      const scrollPosition = scrollableElement.scrollTop;
      setIsFixed(scrollPosition >= navigationFixedThreshold.current);

      setClickedSectionId(null);
    };

    scrollableElement.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateInitialPosition);

    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateInitialPosition);
    };
  }, [scrollableElementRef]);

  return (
    <div
      ref={navigationElementRef}
      className={cx('test-results-navigation', className)}
    >
      <div
        className={cx('test-results-navigation__overlay', {
          'test-results-navigation__fixed': isFixed
        })}
      >
        <div className='test-results-navigation__top-overlay'></div>
        <div className='test-results-navigation__content'>
          <Typography
            element='p'
            variant='overline'
            className='test-results-navigation__title'
          >
            <FormattedMessage
              id='app.quick-navigation'
              defaultMessage='QUICK NAVIGATION'
            />
          </Typography>
          <div className='test-results-navigation__links-wrapper'>
            {linksData.map((link, index) => (
              <Link
                key={index}
                sectionsId={link.sectionsId}
                textId={link.textId}
                isActive={link.sectionsId === activeSection}
                clickedSectionId={clickedSectionId}
                setClickedSectionId={setClickedSectionId}
                scrollingDisabled={scrollingDisabled}
                setScrollingDisabled={setScrollingDisabled}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TestResultsNavigation.propTypes = {
  className: PropTypes.string,
  linksData: PropTypes.array.isRequired,
  activeSection: PropTypes.string,
  scrollableElementRef: PropTypes.object
};

export default TestResultsNavigation;
