import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

// TODO: Change it with our circle from design
const LoadingCircle = ({ className }) => {
  return <span className={`loading-circle ${className}`}></span>;
};

LoadingCircle.propTypes = {
  className: PropTypes.string
};

export default LoadingCircle;
