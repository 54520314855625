import SkinCheckHero from '../../../assets/images/skin-check-hero-section.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'skin-check',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.skin-check.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: SkinCheckHero,
      text: 'learnMoreContent.skin-check.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.skin-check.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.skin-check.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.skin-check.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.skin-check.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.skin-check.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.skin-check.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.skin-check.riskGroups.listItem6', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.skin-check.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.skin-check.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.skin-check.whatToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.skin-check.whatToCheck.listItem3', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.skin-check.frequency.title',
      description: { text: 'learnMoreContent.skin-check.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.skin-check.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.skin-check.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.skin-check.howToCheck.listItem2', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.skin-check.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem4', type: 'text' },
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem5', type: 'text' },
        { text: 'learnMoreContent.skin-check.preventiveMeasures.listItem6', type: 'text' },
      ]
    }
  ]
}