import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useIntl } from 'react-intl';

import { Body1 } from '../Typography';

import styles from './index.module.scss';

interface ListItemTextProps {
  icon: JSX.Element;
  text: string;
}

const ListItemText: React.FC<ListItemTextProps> = ({ icon, text }) => {
  const intl = useIntl();

  const listItemText = intl.formatMessage({
    id: text
  });
  return (
    <div className={styles.wrapper}>
      {icon}

      <Markdown
        components={{
          p: ({ node, ...props }) => <Body1 {...props} />
        }}
        rehypePlugins={[rehypeRaw as any]}
      >
        {listItemText}
      </Markdown>
    </div>
  );
};

export default ListItemText;
