import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Body1, Body2, Heading5 } from '../Typography';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import styles from './index.module.scss';

export interface ReportBoxProps {
  text: string;
  imageURL?: string;
  name?: string;
  className?: string;
  id?: string;
}

const ReportBox: React.FC<ReportBoxProps> = ({
  text,
  imageURL,
  name,
  className,
  id
}) => {
  return (
    <div id={id} className={cx({ [styles.wrapper]: true }, className)}>
      <Markdown
        components={{
          p: ({ node, ...props }) => <Body2 {...props} />,
          li: ({ node, ...props }) => <Body2 element='li' {...props} />
        }}
        rehypePlugins={[rehypeRaw as any]}
      >
        {text}
      </Markdown>

      {name && (
        <div className={styles.imageWrapper}>
          {imageURL && <img src={imageURL} alt='avatar' />}

          <div className={styles.textWrapper}>
            <Body1>
              <FormattedMessage
                id='app.best-regards'
                defaultMessage='Best Regards,'
              />
            </Body1>
            <Heading5>{name}</Heading5>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportBox;
