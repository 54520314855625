import React from 'react';
import cx from 'classnames';

import LoadingCircle from '../../../app/components/LoadingCircle';

import { ReactComponent as IconCompleted } from '../../../assets/svg/green-checkbox-icon.svg';
import { ReactComponent as IconIncomplete } from '../../../assets/svg/grey-checkbox-icon.svg';

import styles from './index.module.scss';

interface ButtonWithIconProps {
  isMarkedAsCompleted: boolean;
  isButtonLoading: boolean;
  children: React.ReactNode;
  handleButtonOnClick: () => void;
  error: object;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  isMarkedAsCompleted,
  isButtonLoading,
  children,
  handleButtonOnClick,
  error
}) => {
  return (
    <button
      className={cx(styles.button, {
        [styles.buttonLoading]: isButtonLoading,
        [styles.buttonCompleted]: isMarkedAsCompleted,
        [styles.buttonError]: error
      })}
      onClick={handleButtonOnClick}
    >
      <div className={styles.iconWrapper}>
        {isMarkedAsCompleted && !isButtonLoading && <IconCompleted />}
        {!isMarkedAsCompleted && !isButtonLoading && <IconIncomplete />}
        {isButtonLoading && <LoadingCircle className={styles.loadingCircle} />}
      </div>
      {children}
    </button>
  );
};

export default ButtonWithIcon;
