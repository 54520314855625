import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from '../../../../components/Link';
import TagsList from '../TagsList';

import { ReactComponent as UncheckedIcon } from '../../../../assets/svg/checkbox-with-grey-border.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/checkbox-checked-purple.svg';
import { Body2, Subtitle1, Subtitle2 } from '../../../../components/Typography';
import { ButtonVariants } from '../../../../components/Button';

import './index.scss';

const CheckupAccordionProductCard = ({
  description,
  image,
  link,
  name,
  price,
  tags,
  variantId,
  className,
  onToggle,
  addToCartUrl,
  isChecked
}) => {
  const intl = useIntl();

  const convertProductTagsToString = tags => {
    const productTags = intl.formatMessage({
      id: tags
    });

    return productTags;
  };

  const toggleCheckbox = () => {
    onToggle(variantId);
  };

  return (
    <div className={cx({ 'checkup-accordion-product-card': true }, className)}>
      <div className='checkup-accordion-product-card__image-and-text-wrapper'>
        <button
          onClick={onToggle && toggleCheckbox}
          className={cx({
            'checkup-accordion-product-card__image-wrapper': true,
            'checkup-accordion-product-card__image-wrapper--no-cursor': !onToggle
          })}
        >
          <img
            className='checkup-accordion-product-card__image'
            src={image}
            alt='product'
          />
          {onToggle &&
            (isChecked ? (
              <CheckedIcon className='checkup-accordion-product-card__checkbox' />
            ) : (
              <UncheckedIcon className='checkup-accordion-product-card__checkbox' />
            ))}
        </button>
        <div className='checkup-accordion-product-card__text-wrapper'>
          <div className='checkup-accordion-product-card__title-and-price-wrapper'>
            <Subtitle2 className='checkup-accordion-product-card__title'>
              <FormattedMessage id={name} />
            </Subtitle2>
            <Subtitle1 className='checkup-accordion-product-card__price--desktop'>
              {price}
            </Subtitle1>
          </div>
          <TagsList tags={convertProductTagsToString(tags)} />
          <Body2 className='checkup-accordion-product-card__description'>
            <FormattedMessage id={description} />
          </Body2>
          <Link
            className='checkup-accordion-product-card__learn-more--desktop'
            url={link}
            size='small'
            variant={ButtonVariants.OUTLINED_GRADIENT}
          >
            <FormattedMessage
              id='learnMoreContent.learn-more'
              defaultMessage='Learn more'
            />
          </Link>

          {addToCartUrl && (
            <Link
              url={addToCartUrl}
              size='small'
              className='checkup-accordion-product-card__add-to-cart--desktop'
            >
              <FormattedMessage
                id='learnMoreContent.add-to-cart'
                defaultMessage='Add to cart'
              />
            </Link>
          )}
        </div>
      </div>

      <div className='checkup-accordion-product-card__price-and-button-wrapper'>
        <Subtitle1 className='checkup-accordion-product-card__price--mobile'>
          {price}
        </Subtitle1>
        <Link
          className='checkup-accordion-product-card__learn-more--mobile'
          url={link}
          size='small'
          variant={ButtonVariants.OUTLINED_GRADIENT}
        >
          <FormattedMessage
            id='learnMoreContent.learn-more'
            defaultMessage='Learn more'
          />
        </Link>
      </div>

      {addToCartUrl && (
        <div className='checkup-accordion-product-card__add-to-cart--mobile'>
          <Link url={addToCartUrl}>
            <FormattedMessage
              id='learnMoreContent.add-to-cart'
              defaultMessage='Add to cart'
            />
          </Link>
        </div>
      )}
    </div>
  );
};

CheckupAccordionProductCard.propTypes = {
  isProductSelected: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  price: PropTypes.string,
  tags: PropTypes.string,
  variantId: PropTypes.string,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  addToCartUrl: PropTypes.string,
  isChecked: PropTypes.bool
};

export default CheckupAccordionProductCard;
