import { Map } from 'immutable';

import {
  START_CONVERSATION_WITH_TEST_CODE_START,
  START_CONVERSATION_WITH_TEST_CODE_SUCCESS,
  START_CONVERSATION_WITH_TEST_CODE_ERROR,
  START_CONVERSATION_WITH_BENEFIT_CODE_START,
  START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS,
  START_CONVERSATION_WITH_BENEFIT_CODE_ERROR,
  START_CONVERSATION_START,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_ERROR,
  UPDATE_CONVERSATION_START,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_ERROR,
  CONTINUE_CONVERSATION_START,
  CONTINUE_CONVERSATION_SUCCESS,
  CONTINUE_CONVERSATION_ERROR,
  SUBMIT_ANSWER_START,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_ERROR,
  RESET_ANSWER_ERROR,
  GO_BACK_START,
  GO_BACK_SUCCESS,
  GO_BACK_ERROR,
  UPDATE_TOKEN,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR,
  CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_START,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_SUCCESS,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_ERROR,
  RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS_IN_FLOW
} from '../actions/data';

const initialState = Map({
  loading: false,
  error: null,
  data: null,
  markAsCompleteButtonLoading: false,
  markAsCompleteButtonSuccess: false
});

const actionsMap = {
  [UPDATE_TOKEN]: (state, action) => {
    return state.merge(
      Map({
        token: action.data
      })
    );
  },
  [START_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [CREATE_PRESCRIPTION_CONVERSATION_WITH_ID_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_WITH_TEST_CODE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [START_CONVERSATION_WITH_BENEFIT_CODE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [UPDATE_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [UPDATE_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [UPDATE_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CONTINUE_CONVERSATION_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [CONTINUE_CONVERSATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [CONTINUE_CONVERSATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [SUBMIT_ANSWER_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [SUBMIT_ANSWER_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [SUBMIT_ANSWER_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [RESET_ANSWER_ERROR]: state => {
    return state.merge(
      Map({
        error: null
      })
    );
  },
  [GO_BACK_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [GO_BACK_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [GO_BACK_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_START]: state => {
    return state.merge(
      Map({
        markAsCompleteButtonLoading: true,
        markAsCompleteButtonSuccess: false,
        error: null
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_SUCCESS]: (state, action) => {
    const { key, completed } = action.data;
    const currentData = state.get('data');
    const { checkUpCards } = currentData.content.data;
    const updatedCards = checkUpCards.cards.map(card => {
      if (key === card.key) {
        return { ...card, completed: completed };
      }

      return card;
    });

    currentData.content.data.checkUpCards.cards = updatedCards;

    return state.merge(
      Map({
        markAsCompleteButtonLoading: false,
        markAsCompleteButtonSuccess: true,
        error: null,
        data: currentData
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_IN_FLOW_ERROR]: (state, action) => {
    return state.merge(
      Map({
        markAsCompleteButtonLoading: false,
        markAsCompleteButtonSuccess: false,
        error: action.error
      })
    );
  },
  [RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS_IN_FLOW]: state => {
    return state.merge(
      Map({
        markAsCompleteButtonSuccess: false
      })
    );
  }
};



export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
