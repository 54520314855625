import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import { ButtonVariants } from '../Button';

import styles from './index.module.scss';

export type OuterLinkProps = {
  variant?:
    | 'primary'
    | 'outlined'
    | 'text'
    | 'elevated'
    | 'tonal'
    | 'outlinedGradient';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  href: string;
  innerRef?: React.RefObject<HTMLAnchorElement>;
  className?: string;
  disabled?: boolean;
};

const OuterLink: React.FC<OuterLinkProps> = ({
  variant = 'primary',
  size = 'normal',
  children,
  href,
  innerRef,
  className,
  disabled
}) => {
  const linkClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  const isOutlinedGradient = variant === ButtonVariants.OUTLINED_GRADIENT;
  const isSizeSmall = size === 'small';

  return (
    <a
      className={cx(
        {
          [linkClassName]: true,
          [styles.disabled]: disabled
        },
        className && { [className]: true }
      )}
      href={href}
      ref={innerRef}
      target='_blank'
      rel='noopener noreferrer'
      aria-disabled={disabled}
    >
      {isOutlinedGradient ? (
        <span
          className={cx(styles.gradientContent, {
            [styles.smallGradientContent]: isSizeSmall
          })}
        >
          <span className={styles.gradientText}>{children}</span>
        </span>
      ) : (
        children
      )}
    </a>
  );
};

export default OuterLink;
