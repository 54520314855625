import ProstateCancerHero from '../../../assets/images/prostate-cancer-screening-hero-section.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'prostate-cancer-screening',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.prostate-cancer-screening.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: ProstateCancerHero,
      text: 'learnMoreContent.prostate-cancer-screening.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.prostate-cancer-screening.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.prostate-cancer-screening.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.riskGroups.listItem4', type: 'text' }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.prostate-cancer-screening.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.prostate-cancer-screening.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.whatToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.whatToCheck.listItem3', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.prostate-cancer-screening.frequency.title',
      description:
        { text: 'learnMoreContent.prostate-cancer-screening.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.prostate-cancer-screening.howToCheck.title',
      iconType: 'success',
      list: [{ text: 'learnMoreContent.prostate-cancer-screening.howToCheck.listItem1', type: 'text' }],
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title:
        'learnMoreContent.prostate-cancer-screening.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.prostate-cancer-screening.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.prostate-cancer-screening.preventiveMeasures.listItem4', type: 'text' },
      ]
    }
  ]
}