import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckupCards from '../CheckupCards';
import CheckupBanner from '../CheckupBanner';
import CheckupAccordions from '../CheckupAccordions';

import './index.scss';

const CheckupRecommendation = ({
  data,
  dispatch,
  markAsCompleteButtonLoading,
  markAsCompleteButtonError,
  markAsCompleteButtonSuccess
}) => {
  const { banner, actionAccordions } = data.data;

  return (
    <div className='checkup-recommendation'>
      <CheckupCards
        checkUpRecommendations={data.data}
        dispatch={dispatch}
        loading={markAsCompleteButtonLoading}
        checkUpRecommendationInFlow={true}
        error={markAsCompleteButtonError}
        success={markAsCompleteButtonSuccess}
      />
      <CheckupBanner type={banner.type} text={banner.text} />
      <CheckupAccordions
        title={actionAccordions.title}
        actions={actionAccordions.actions}
      />
    </div>
  );
};

CheckupRecommendation.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  markAsCompleteButtonLoading: PropTypes.bool,
  markAsCompleteButtonError: PropTypes.object,
  markAsCompleteButtonSuccess: PropTypes.bool
};

const mapStateToProps = state => ({
  markAsCompleteButtonLoading: state.data.get('markAsCompleteButtonLoading'),
  markAsCompleteButtonError: state.data.get('error'),
  markAsCompleteButtonSuccess: state.data.get('markAsCompleteButtonSuccess')
});

export default connect(mapStateToProps)(CheckupRecommendation);
