import IronCheck from '../../../assets/images/iron-check-hero-section.png';
import IronTrackingImage from '../../../assets/images/iron-tracking.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'iron-check',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.iron-check.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: IronCheck,
      text: 'learnMoreContent.iron-check.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.iron-check.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.iron-check.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem6', type: 'text' },
        { text: 'learnMoreContent.iron-check.riskGroups.listItem7', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.iron-check.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.iron-check.whatToCheck.listItem1', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.iron-check.frequency.title',
      description: { text: 'learnMoreContent.iron-check.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.iron-check.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.iron-check.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.iron-check.howToCheck.listItem2', type: 'text' },
      ],
      product: {
        name:
          'learnMoreContent.iron-check.howToCheck.product.ironTracking.name',
        description:
          'learnMoreContent.iron-check.howToCheck.product.ironTracking.description',
        type: 'ironTracking',
        price: 'CHF 79',
        tags:
          'learnMoreContent.iron-check.howToCheck.product.ironTracking.tags',
        link:
          'https://yourself.health/products/iron-test-from-home-ferritin-crp',
        image: IronTrackingImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39364123787350:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.iron-check.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.iron-check.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.iron-check.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.iron-check.preventiveMeasures.listItem3', type: 'text' },
      ]
    }
  ]
}