import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import ButtonWithCheckbox from './ButtonWithCheckbox';
import Link, { LinkVariants } from '../Link';

import { Body1, Body2, Subtitle1 } from '../Typography';

import PopUpImage from '../../assets/images/party-hat-with-bravo-text.png';
import { ReactComponent as CalendarIcon } from '../../assets/svg/calendar-icon-for-checkup-card.svg';

import styles from './index.module.scss';

export interface CheckupCardProps {
  imageUrl: string;
  title: string;
  subtitle: string;
  description: string;
  leftButtonText: string;
  completed: boolean;
  isButtonLoading: boolean;
  rightButtonText: string;
  handleLeftButtonClick: () => void;
  rightButtonURL: string;
  error: object;
  success: boolean;
}

const CheckupCard: React.FC<CheckupCardProps> = ({
  imageUrl,
  title,
  subtitle,
  description,
  completed,
  isButtonLoading,
  leftButtonText,
  rightButtonText,
  rightButtonURL,
  handleLeftButtonClick,
  error,
  success
}) => {
  return (
    <div
      className={cx(styles.wrapper, {
        [styles.wrapperCompleted]: completed
      })}
    >
      <div className={styles.imageAndTextWrapper}>
        <img className={styles.image} src={imageUrl} alt='Health check' />
        <div className={styles.textWrapperMobile}>
          <Subtitle1 element='p' className={styles.title}>
            {title}
          </Subtitle1>
          <div className={styles.subtitleWrapper}>
            <CalendarIcon className={styles.calendarIcon} />
            <Body2 element='p' className={styles.subtitle}>
              {subtitle}
            </Body2>
          </div>
        </div>
      </div>
      <div className={styles.rightSideWrapper}>
        <div className={styles.textWrapperDesktop}>
          <Subtitle1 element='p' className={styles.title}>
            {title}
          </Subtitle1>
          <div className={styles.subtitleWrapper}>
            <CalendarIcon className={styles.calendarIcon} />
            <Body2 element='p' className={styles.subtitle}>
              {subtitle}
            </Body2>
          </div>
        </div>
        <Body1 element='p' className={styles.description}>
          {description}
        </Body1>
        <div className={styles.buttonsWrapper}>
          <ButtonWithCheckbox
            isMarkedAsCompleted={completed}
            isButtonLoading={isButtonLoading}
            handleButtonOnClick={handleLeftButtonClick}
            error={error}
          >
            <FormattedMessage id={leftButtonText} />
          </ButtonWithCheckbox>
          <Link
            variant={LinkVariants.OUTLINED_GRADIENT}
            url={rightButtonURL}
            size='small'
            className={completed ? styles.learnMoreLinkWhenCompleted : ''}
          >
            <FormattedMessage id={rightButtonText} />
          </Link>
        </div>
      </div>

      <img
        className={cx(styles.popUpImage, { [styles.showPopUp]: success })}
        src={PopUpImage}
        alt='Success'
      />
    </div>
  );
};

export default CheckupCard;
