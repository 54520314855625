export const testTypes: Record<string, string> = {
  ALLERGY_360: 'allergy360',
  COVID_ANTIBODIES: 'covidLight',
  COVID_BOOSTER: 'booster',
  COVID_IMMUNITY: 'covidImmmunity',
  COVID_PCR: 'covidpcr',
  FATIGUE: 'fatigue',
  FATIGUE_V2: 'fatigueV2',
  FATIGUE_V3: 'fatigueV3',
  FATIGUE_V4: 'fatigueV4',
  HEALTH_CHECK_BASIC: 'healthCheckBasic',
  HEALTH_CHECK_BASIC_V2: 'healthCheckBasicV2',
  HEALTH_CHECK_BASIC_V3: 'healthCheckBasicV3',
  HEALTH_CHECK_BASIC_V4: 'healthCheckBasicV4',
  HEALTH_RISK_CHECK: 'healthRiskCheck',
  HAYFEVER: 'hayfever',
  IRON: 'iron',
  IRON_V2: 'ironV2',
  NUTRITION_360: 'n360',
  NUTRITION_360_V2: 'n360V2',
  NUTRITION_360_V3: 'n360V3',
  STD_PREMIUM: 'stdPremium',
  STD_PREMIUM_V2: 'stdPremiumV2',
  STD_BASICS_MALE: 'stdBasicsMale',
  STD_BASICS_FEMALE: 'stdBasicsFemale',
  STD_BASICS_ANAL: 'stdBasicsAnal',
  STD_BASICS_ORAL: 'stdBasicsOral',
  FERTILITY_CHECK: 'fertilityCheck',
  HPV_CHECK: 'hpvCheck',
  VITAMIN_B12: 'b12',
  VITAMIN_D: 'd',
  ADVANCED_IRON: 'advancedIron',
  COLON_CANCER: 'colonCancer',
  BASIC_ALLERGY_AND_HAYFEVER_CHECK: 'basicAllergyAndHayfeverCheck',
  ALLERGY_CHECK_FOR_IMMUNOTHERAPY: 'allergyCheckForImmunotherapy',
  HEALTH_CHECK_VEGAN_AND_VEGETARIAN: 'healthCheckVeganAndVegetarian',
  HEALTH_CHECK_VEGAN_AND_VEGETARIAN_V2: 'healthCheckVeganAndVegetarian',
  HEALTH_CHECK_ATHLETES: 'healthCheckAthletes',
  URINARY_TRACT_INFECTION: 'urinaryTractInfection',
  IMMUNOTHERAPY_CHECK_POLLEN: 'immunotherapyCheckPollen',
  MONKEY_POX: 'monkeyPox'
};
