import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonVariants } from '../../../components/Button';

const InfoButton = ({ cta, handleInfo, className }) => {
  return (
    <Button
      onClick={() => handleInfo(cta.infoText)}
      size='small'
      className={className}
      variant={ButtonVariants.ELEVATED}
    >
      {cta.text}
    </Button>
  );
};

InfoButton.propTypes = {
  cta: PropTypes.object,
  handleInfo: PropTypes.func,
  className: PropTypes.string
};

export default InfoButton;
