import React from 'react';
import PropTypes from 'prop-types';

import BasicContainer from '../../components/BasicContainer';
import MyHealthContainer from '../../components/MyHealthContainer';

const AlwaysAccessibleRouteWrapper = ({
  children,
  isAuthenticated,
  lang,
  changeLanguage,
  dispatch
}) => {
  if (!isAuthenticated) {
    return (
      <BasicContainer lang={lang} changeLanguage={changeLanguage}>
        {children}
      </BasicContainer>
    );
  }

  return (
    <MyHealthContainer
      lang={lang}
      changeLanguage={changeLanguage}
      dispatch={dispatch}
    >
      {children}
    </MyHealthContainer>
  );
};

AlwaysAccessibleRouteWrapper.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default AlwaysAccessibleRouteWrapper;
