import React from 'react';
import PropTypes from 'prop-types';
import Link, { LinkVariants } from '../../../components/Link';

const LearnMoreButton = ({ cta, className }) => {
  return (
    <Link
      variant={LinkVariants.ELEVATED}
      size='small'
      url={cta.url}
      className={className}
    >
      {cta.text}
    </Link>
  );
};

LearnMoreButton.propTypes = {
  cta: PropTypes.object,
  className: PropTypes.string
};

export default LearnMoreButton;
