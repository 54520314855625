import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Heading3 } from '../../../components/Typography';

import './index.scss';

const LearnMorePostTitle = ({ text }) => {
  return (
    <Heading3 className='learn-more-post-title'>
      <FormattedMessage id={text} defaultMessage='Title' />
    </Heading3>
  );
};

LearnMorePostTitle.propTypes = {
  text: PropTypes.string
};

export default LearnMorePostTitle;
