import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CheckupCard from '../../../components/CheckupCard';

import { Heading3, Overline } from '../../../components/Typography';

import {
  toggleCheckupCardCompletionStatus,
  resetMarkAsCompleteButtonSuccess
} from '../../actions/dashboard';
import {
  toggleCheckupCardCompletionStatusInFlow,
  resetMarkAsCompleteButtonSuccessInFlow
} from '../../actions/data';

import './index.scss';

const SUCCESS_TIMEOUT = 3000;

const CheckupCards = ({
  checkUpRecommendations,
  dispatch,
  loading,
  checkUpRecommendationInFlow,
  error,
  success
}) => {
  const { eyebrow, title, cards } = checkUpRecommendations.checkUpCards;
  const [clickedCardKey, setClickedCardKey] = useState(null);
  const [successTimeout, setSuccessTimeout] = useState(null);

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        if (checkUpRecommendationInFlow) {
          dispatch(resetMarkAsCompleteButtonSuccessInFlow());
        } else {
          dispatch(resetMarkAsCompleteButtonSuccess());
        }
      }, SUCCESS_TIMEOUT);
      setSuccessTimeout(timeout);
    }
  }, [checkUpRecommendationInFlow, dispatch, success]);

  const handleMarkAsCompleteButtonClick = key => {
    if (successTimeout) {
      clearTimeout(successTimeout);
    }
    setClickedCardKey(key);
    if (checkUpRecommendationInFlow) {
      dispatch(
        toggleCheckupCardCompletionStatusInFlow(checkUpRecommendations.id, key)
      );
    } else {
      dispatch(
        toggleCheckupCardCompletionStatus(checkUpRecommendations.id, key)
      );
    }
  };

  return (
    <div className='checkup-cards'>
      <Overline element='p' className='checkup-cards__overline'>
        {eyebrow}
      </Overline>
      <Heading3 className='checkup-cards__title'>{title}</Heading3>

      <div className='checkup-cards__cards'>
        {cards.map(card => {
          const learnMoreCTA = card.CTAs.find(cta => cta.type === 'link');

          return (
            <CheckupCard
              key={card.key}
              title={card.name}
              subtitle={card.frequency}
              description={card.description}
              leftButtonText={
                card.completed
                  ? 'check-ups.completed'
                  : 'check-ups.mark-as-completed'
              }
              rightButtonText='check-ups.learn-more'
              rightButtonURL={learnMoreCTA.url || '#'}
              completed={card.completed}
              isButtonLoading={loading && clickedCardKey === card.key}
              handleLeftButtonClick={() =>
                handleMarkAsCompleteButtonClick(card.key)
              }
              imageUrl={card.icon}
              error={error && clickedCardKey === card.key}
              success={card.completed && success && clickedCardKey === card.key}
            />
          );
        })}
      </div>
    </div>
  );
};

CheckupCards.propTypes = {
  checkUpRecommendations: PropTypes.object,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  checkUpRecommendationInFlow: PropTypes.bool,
  success: PropTypes.bool
};

export default CheckupCards;
