import VitaminB12TrackingHero from '../../../assets/images/b12-check-hero-section.png';
import VitaminB12Image from '../../../assets/images/vitamin-b12-tracking.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'vitamin-b12-check',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.vitamin-b12-check.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: VitaminB12TrackingHero,
      text: 'learnMoreContent.vitamin-b12-check.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.vitamin-b12-check.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.vitamin-b12-check.riskGroups.listItem1', type: "text" },
        { text: 'learnMoreContent.vitamin-b12-check.riskGroups.listItem2', type: "text" },
        { text: 'learnMoreContent.vitamin-b12-check.riskGroups.listItem3', type: "text" },
        { text: 'learnMoreContent.vitamin-b12-check.riskGroups.listItem4', type: "text" },
        { text: 'learnMoreContent.vitamin-b12-check.riskGroups.listItem5', type: "text" }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.vitamin-b12-check.whatToCheck.title',
      iconType: 'success',
      list: [{ text: 'learnMoreContent.vitamin-b12-check.whatToCheck.listItem1', type: "text" }]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.vitamin-b12-check.frequency.title',
      description: { text: 'learnMoreContent.vitamin-b12-check.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.vitamin-b12-check.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.vitamin-b12-check.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.vitamin-b12-check.howToCheck.listItem2', type: 'text' }
      ],
      product: {
        name:
          'learnMoreContent.vitamin-b12-check.howToCheck.product.vitaminB12Tracking.name',
        description:
          'learnMoreContent.vitamin-b12-check.howToCheck.product.vitaminB12Tracking.description',
        type: 'vitaminB12check',
        price: 'CHF 99',
        tags:
          'learnMoreContent.vitamin-b12-check.howToCheck.product.vitaminB12Tracking.tags',
        link: 'https://yourself.health/products/vitamin-b12-test-from-home',
        image: VitaminB12Image,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39525068636246:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.vitamin-b12-check.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.vitamin-b12-check.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.vitamin-b12-check.preventiveMeasures.listItem2', type: 'text' },
      ]
    }
  ]
}


