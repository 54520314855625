import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import LearnMorePageGenerator from '../../components/LearnMorePageGenerator';

import { Heading4 } from '../../../components/Typography';

import learnMorePosts from '../../postsContent';

import './index.scss';

const LearnMorePost = () => {
  const { topic } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = learnMorePosts.find(item => item.key === topic);

  if (!content) {
    return (
      <Heading4 className='learn-more-post__error'>
        <FormattedMessage
          id='learnMoreContent.page-doesnt-exist'
          defaultMessage='The page you are looking for does not exist.'
        />
      </Heading4>
    );
  }
  return (
    <div className='learn-more-post'>
      <LearnMorePageGenerator data={content} />
    </div>
  );
};

export default LearnMorePost;
