import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyHealthHeader from '../../../components/MyHealthHeader';
import NavbarBottom from '../NavbarBottom';

import { getDashboardData } from '../../actions/dashboard';
import { getUser } from '../../actions/user';
import { useAuthState } from '../../context/firebase';
import { useLocation, useHistory } from 'react-router-dom';
import { saveCheckUpRecommendationId } from '../../actions/dashboard';

import './index.scss';

const MyHealthContainer = ({
  children,
  dispatch,
  loading,
  userLoading,
  userData,
  data,
  lang,
  changeLanguage
}) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthState();

  useEffect(() => {
    if (!userData) {
      dispatch(getUser());
      localStorage.removeItem('activeTab');
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (!data && userData && !userLoading) {
      dispatch(getDashboardData(lang));
    }
  }, [data, dispatch, user, userData, userLoading, lang]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const checkUpRecommendationId = searchParams.get('checkUpRecommendationId');

    if (data && checkUpRecommendationId) {
      if (!data.checkUpRecommendations) {
        dispatch(saveCheckUpRecommendationId(checkUpRecommendationId));
      }

      history.replace({
        pathname: location.pathname
      });
    }
  }, [data, dispatch, history, location.pathname, location.search]);

  const handleLanguageChange = language => {
    dispatch(getDashboardData(language));
    changeLanguage(language);
  };

  return (
    <div className='my-health-container'>
      <MyHealthHeader
        availableLanguages={['en', 'de', 'fr']}
        defaultLanguage={lang}
        onChangeLanguage={handleLanguageChange}
        dispatch={dispatch}
      />
      {(loading || userLoading) && (
        <div className='my-health-container__loader'></div>
      )}
      {data && !loading && !userLoading ? children : null}
      <NavbarBottom />
    </div>
  );
};

MyHealthContainer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  userLoading: PropTypes.bool,
  userData: PropTypes.object,
  data: PropTypes.object,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data'),
  loading: state.dashboard.get('loading'),
  userData: state.user.get('user'),
  userLoading: state.user.get('loading'),
  // TODO: Handle those errors
  error: state.dashboard.get('error'),
  userError: state.user.get('error')
});

export default connect(mapStateToProps)(MyHealthContainer);
