import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyHealthContainer from '../../components/MyHealthContainer';
import CheckupCards from '../../components/CheckupCards';
import CheckupAccordions from '../../components/CheckupAccordions';
import CheckupBanner from '../../components/CheckupBanner';
import BannerWithTextAndButton from '../../../components/BannerWithTextAndButton';

import GirlWithHoodieImage from '../../../assets/images/girl-with-hoodie.png';

import './index.scss';

const CHECK_UPS_RECOMMENDATION_FLOW_URL = `${process.env.REACT_APP_SECURE_APP_URL}/?conversationFlowKey=checkUpRecommendation`;

const MyHealthCheckups = ({
  dispatch,
  data,
  markAsCompleteButtonLoading,
  lang,
  changeLanguage,
  markAsCompleteButtonError,
  markAsCompleteButtonSuccess
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MyHealthContainer
      dispatch={dispatch}
      lang={lang}
      changeLanguage={changeLanguage}
    >
      {data && data.checkUpRecommendations && (
        <div className='my-health-checkups'>
          <CheckupCards
            checkUpRecommendations={data.checkUpRecommendations}
            dispatch={dispatch}
            loading={markAsCompleteButtonLoading}
            checkUpRecommendationInFlow={false}
            error={markAsCompleteButtonError}
            success={markAsCompleteButtonSuccess}
          />

          <CheckupBanner
            type={data.checkUpRecommendations.banner.type}
            text={data.checkUpRecommendations.banner.text}
          />
          <CheckupAccordions
            title={data.checkUpRecommendations.actionAccordions.title}
            actions={data.checkUpRecommendations.actionAccordions.actions}
          />
        </div>
      )}
      {data && !data.checkUpRecommendations && (
        <div className='my-health-checkups--no-content'>
          <BannerWithTextAndButton
            imageUrl={GirlWithHoodieImage}
            title='check-ups.your-check-up-planner'
            text='check-ups.figure-out-check-ups-relevant-for-you'
            buttonText='check-ups.answer-a-few-questions'
            buttonUrl={CHECK_UPS_RECOMMENDATION_FLOW_URL}
          />
        </div>
      )}
    </MyHealthContainer>
  );
};

MyHealthCheckups.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  markAsCompleteButtonLoading: PropTypes.bool,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func,
  markAsCompleteButtonError: PropTypes.object,
  markAsCompleteButtonSuccess: PropTypes.bool
};

const mapStateToProps = state => ({
  data: state.dashboard.get('data'),
  markAsCompleteButtonLoading: state.dashboard.get(
    'markAsCompleteButtonLoading'
  ),
  markAsCompleteButtonError: state.dashboard.get('error'),
  markAsCompleteButtonSuccess: state.dashboard.get(
    'markAsCompleteButtonSuccess'
  )
});

export default connect(mapStateToProps)(MyHealthCheckups);
