import React from 'react';
import PropTypes from 'prop-types';
import { Body1, ButtonText } from '../../../../components/Typography';

import './index.scss';

const CheckupAccordionDescription = ({ description, descriptionURL }) => {
  return (
    <div className='checkup-accordion-description'>
      <Body1 className='checkup-accordion-description__text'>
        {description}
      </Body1>
      {descriptionURL && (
        <a
          className='checkup-accordion-description__link'
          href={descriptionURL || '#'}
        >
          {/* TODO: Marko translate this. */}
          <ButtonText element='span'>Learn more</ButtonText>
        </a>
      )}
    </div>
  );
};

CheckupAccordionDescription.propTypes = {
  description: PropTypes.string,
  descriptionURL: PropTypes.string
};

export default CheckupAccordionDescription;
