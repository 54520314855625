import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useIntl } from 'react-intl';

import { Body2 } from '../Typography';

interface BannerItemTextProps {
  text: string;
}

const BannerItemText: React.FC<BannerItemTextProps> = ({ text }) => {
  const intl = useIntl();

  const bannerItemText = intl.formatMessage({
    id: text
  });
  return (
    <Markdown
      components={{
        p: ({ node, ...props }) => <Body2 {...props} />
      }}
      rehypePlugins={[rehypeRaw as any]}
    >
      {bannerItemText}
    </Markdown>
  );
};

export default BannerItemText;
