import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import withScroll from '../../windowDecorators/withScroll';
import withWindow from '../../windowDecorators/withWindow';

import QuestionMapper from '../QuestionMapper';
import Recommendation from '../Recommendation';
import HealthCheckRecommendation from '../HealthCheckRecommendation';
import Risks from '../Risks';
import SlideContent from '../SlideContent';
import Modal from '../Modal';
import RecommendationModal from '../RecommendationModal';
import HayfeverCheckoutModal from '../HayfeverCheckoutModal';
import RiskModal from '../RisksModal';
import ActionsBar from '../ActionsBar';
import TextBlock from '../TextBlock';
import Scorecard from '../Scorecard';
import TestResults from '../TestResult';
import PersonalHayfever from '../PersonalHayfever';
import TreatmentPlan from '../TreatmentPlan';
import LoaderScreen from '../LoaderScreen';
import SliderScreen from '../SliderScreen';
import CheckupRecommendation from '../CheckupRecommendation';
import BookingIframe from '../BookingIframe';

import Header from '../Header';
import ProgressBar from '../ProgressBar';
import PageTitle from '../PageTitle';

import { dataType } from '../../constants/data';

import useIsSticky from '../../hooks/isSticky';

import { getFlowCountry } from '../../utils/helpers';
import { setFlowCountry } from '../../actions/app';

import './index.scss';

const CONTENT_TYPE = {
  [dataType.QUESTION]: QuestionMapper,
  [dataType.RECOMMENDATION]: Recommendation,
  [dataType.CHECKOUT]: Recommendation,
  [dataType.RISKS]: Risks,
  [dataType.TEXT]: TextBlock,
  [dataType.BOOKING_IFRAME]: BookingIframe,
  [dataType.SCORECARD]: Scorecard,
  [dataType.TEST_RESULTS]: TestResults,
  [dataType.HAYFEVER_SEASONS]: PersonalHayfever,
  [dataType.TREATMENT_PLAN]: TreatmentPlan,
  [dataType.LOADER]: LoaderScreen,
  [dataType.SLIDER]: SliderScreen,
  [dataType.HEALTH_CHECK_RECOMMENDATION]: HealthCheckRecommendation,
  [dataType.CHECKUP_RECOMMENDATION]: CheckupRecommendation
};

const MODAL_TYPE = {
  [dataType.QUESTION]: Modal,
  [dataType.RECOMMENDATION]: RecommendationModal,
  [dataType.CHECKOUT]: HayfeverCheckoutModal,
  [dataType.SCORECARD]: RiskModal
};

const ANIMATION_DURATION = 500;

const getScreenTitle = content => {
  let title = '';

  if (content.kind === dataType.QUESTION) {
    title = content.question.questionText;
  } else if (content.kind === 'info') {
    title = content.info.titleText;
  } else if (content.kind === dataType.RECOMMENDATION) {
    title = 'Recommended Tests';
  } else if (content.kind === dataType.HAYFEVER_SEASONS) {
    title = content.info.title;
  } else if (
    content.kind === dataType.HEALTH_CHECK_RECOMMENDATION ||
    content.kind === dataType.SLIDER
  ) {
    title = content.title;
  } else if (content.kind === dataType.CHECKOUT) {
    title = 'Checkout';
  } else if (content.kind === dataType.BOOKING_IFRAME) {
    title = content.info.title;
  }

  // TODO: check with DP if this can stay
  if (title === '[Sorry: text missing]') {
    title = null;
  }

  return title;
};

const getMainDescription = content => {
  let mainDescription = false;

  if (content.kind === dataType.QUESTION) {
    mainDescription = content.question.hasMainDescription
      ? content.question.mainDescriptionText
      : false;
  } else if (content.kind === 'info') {
    mainDescription = content.info.hasMainDescription
      ? content.question.mainDescriptionText
      : false;
  } else if (content.kind === dataType.HAYFEVER_SEASONS) {
    mainDescription = content.info.hasMainDescription
      ? content.question.mainDescriptionText
      : false;
  }

  return mainDescription;
};

const handleModalClosing = (
  setIsModalOpen,
  setSelectedTest,
  setIsModalClosing
) => () => {
  setIsModalClosing(true);
  setTimeout(() => {
    setIsModalOpen(false);
    setSelectedTest(null);
    setIsModalClosing(false);
  }, ANIMATION_DURATION);
};

const Content = ({
  data,
  scrollPositionY,
  dispatch,
  breakpoint,
  dataLoading,
  searchParams,
  changeLanguage,
  language,
  userData,
  userLoading,
  isAllergyCheckRecommendation
}) => {
  const [currentScreenAnswer, setCurrentScreenAnswer] = useState([]);
  // For recommendation page
  const [basketButtonHref, setBasketButtonHref] = useState(null);
  const [animationDirection, setAnimationDirection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Setting here so we can control it from ActionBar (ESC button click)
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [recommendedModalData, setRecommendedModalData] = useState(null);
  // Test that is selected to be opened in modal (test's info)
  const [selectedTest, setSelectedTest] = useState(null);

  const { content, actions, conversationId, conversationFlowStateKey, testCode } = data;
  const componentKind =
    content.kind === 'info' ? content.info.kind : content.kind;
  const ContentComponent = CONTENT_TYPE[componentKind];
  const ModalComponent = MODAL_TYPE[componentKind];

  const isSmall = ['xsmall', 'small'].includes(breakpoint);

  const stickyPosition = isSmall ? 80 : 186;
  const isTitleSticky = useIsSticky(scrollPositionY, stickyPosition);

  const title = getScreenTitle(data.content);
  const mainDescription = getMainDescription(data.content);

  const contentClasses = cx({
    content: true,
    'content--with-sticky-title':
      isTitleSticky && isSmall && !!title && !isAllergyCheckRecommendation
  });

  const storageFlowCountry = localStorage.getItem('currentFlowCountry');
  let flowCountry = storageFlowCountry;
  if (data && storageFlowCountry !== getFlowCountry(data.conversationFlowKey)) {
    flowCountry = getFlowCountry(data.conversationFlowKey);
  }

  const [hasFlowCountry, setHasFlowCountry] = useState(false);
  useEffect(() => {
    if (data) {
      const storageFlowCountry = localStorage.getItem('currentFlowCountry');
      let flowCountry = storageFlowCountry;
      if (storageFlowCountry !== getFlowCountry(data.conversationFlowKey)) {
        flowCountry = getFlowCountry(data.conversationFlowKey);
      }
      localStorage.setItem('currentFlowCountry', flowCountry);
      if (!hasFlowCountry) {
        dispatch(setFlowCountry(flowCountry));
        setHasFlowCountry(true);
      }
    }
  }, [data, dispatch, hasFlowCountry]);

  return (
    <div className={contentClasses}>
      <Header
        searchParams={searchParams}
        conversationFlowKey={data.conversationFlowKey}
        restartConversationFlowKey={data.restartConversationFlowKey}
        flowCountry={flowCountry}
        changeLanguage={changeLanguage}
        language={language}
        availableLanguages={data.availableLanguages}
        userData={userData}
      />
      <ProgressBar data={data} isTitleSticky={isTitleSticky && !!title} />
      {title && (
        <PageTitle
          isSticky={isTitleSticky && !!title}
          animationDirection={animationDirection}
          text={title}
          mainDescription={mainDescription}
          setModalData={setModalData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          // Data that trigger slide animation
          changingData={conversationFlowStateKey}
          isAllergyCheckRecommendation={isAllergyCheckRecommendation}
        />
      )}
      <SlideContent
        animationDirection={animationDirection}
        changingData={conversationFlowStateKey}
      >
        {dataLoading && <div className='content__loading-overlay' />}
        <ContentComponent
          setIsModalOpen={setIsModalOpen}
          data={content}
          dataLoading={dataLoading}
          // For type question
          currentScreenAnswer={currentScreenAnswer}
          setCurrentScreenAnswer={setCurrentScreenAnswer}
          setModalData={setModalData}
          isModalOpen={isModalOpen}
          // For recommendation
          setBasketButtonHref={setBasketButtonHref}
          setSelectedTest={setSelectedTest}
          setRecommendedModalData={setRecommendedModalData}
          flowCountry={flowCountry}
          userData={userData}
          userLoading={userLoading}
          dispatch={dispatch}
          language={language}
          // for loader
          conversationId={conversationId}
          conversationFlowStateKey={conversationFlowStateKey}
          setAnimationDirection={setAnimationDirection}
          testCode={testCode}
          // For switchboard
          switchboardTestCode={
            searchParams &&
            searchParams.conversationFlowKey === 'switchboard' &&
            searchParams.testCode
              ? searchParams.testCode
              : ''
          }
        />
      </SlideContent>
      <ActionsBar
        setAnimationDirection={setAnimationDirection}
        animationDirection={animationDirection}
        actions={actions}
        answer={currentScreenAnswer}
        setCurrentScreenAnswer={setCurrentScreenAnswer}
        contentKind={componentKind}
        dispatch={dispatch}
        conversationId={conversationId}
        question={content.question}
        basketButtonHref={basketButtonHref}
        dataLoading={dataLoading}
        conversationFlowStateKey={conversationFlowStateKey}
        closeSource={searchParams && searchParams.source}
        closeModalHandler={handleModalClosing(
          setIsModalOpen,
          setSelectedTest,
          setIsModalClosing
        )}
        isModalOpen={isModalOpen}
        flowCountry={flowCountry}
      />
      {isModalOpen && (
        <ModalComponent
          small={content.kind === dataType.QUESTION ? true : false}
          onCloseButtonClick={handleModalClosing(
            setIsModalOpen,
            setSelectedTest,
            setIsModalClosing
          )}
          data={modalData}
          recommendedModalData={recommendedModalData}
          selectedTest={selectedTest}
          isModalClosing={isModalClosing}
          flowCountry={flowCountry}
        >
          {modalData}
        </ModalComponent>
      )}
    </div>
  );
};

Content.propTypes = {
  data: PropTypes.object,
  scrollPositionY: PropTypes.number,
  dispatch: PropTypes.func,
  breakpoint: PropTypes.string,
  dataLoading: PropTypes.bool,
  searchParams: PropTypes.object,
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  flowCountry: PropTypes.string,
  userData: PropTypes.object,
  userLoading: PropTypes.bool,
  isAllergyCheckRecommendation: PropTypes.bool
};

export default connect()(withWindow(withScroll(Content)));
