import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import { ButtonVariants } from '../Button';

import styles from './index.module.scss';

export type InnerLinkProps = {
  variant?:
    | 'primary'
    | 'outlined'
    | 'text'
    | 'elevated'
    | 'tonal'
    | 'outlinedGradient';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  to: string;
  className?: string;
  disabled?: boolean;
};

const InnerLink: React.FC<InnerLinkProps> = ({
  to,
  children,
  className,
  variant = 'primary',
  size = 'normal',
  disabled
}) => {
  const linkClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  const isOutlinedGradient = variant === ButtonVariants.OUTLINED_GRADIENT;
  const isSizeSmall = size === 'small';

  return (
    <Link
      className={cx(
        {
          [linkClassName]: true,
          [styles.disabled]: disabled
        },
        className && { [className]: true }
      )}
      to={to}
      aria-disabled={disabled}
    >
      {isOutlinedGradient ? (
        <span
          className={cx(styles.gradientContent, {
            [styles.smallGradientContent]: isSizeSmall
          })}
        >
          <span className={styles.gradientText}>{children}</span>
        </span>
      ) : (
        children
      )}
    </Link>
  );
};

export default InnerLink;
