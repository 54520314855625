import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ButtonText, Overline } from '../../../../components/Typography';

import './index.scss';

const CheckupAccordionAddToCartButton = ({ selectedProducts }) => {
  let cartURL = null;

  if (selectedProducts.length > 0) {
    const formattedProducts = selectedProducts
      .map(productId => `${productId}:1`)
      .join(',');

    cartURL = `https://hy-demo.myshopify.com/cart/${formattedProducts}?storefront=true`;
  }

  return (
    <a
      href={cartURL}
      rel='noopener noreferrer'
      target='_blank'
      className={cx({
        'checkup-accordion-add-to-cart-button': true,
        'checkup-accordion-add-to-cart-button--disabled': !cartURL
      })}
    >
      <ButtonText>
        <FormattedMessage
          id='actions.add-to-basket'
          defaultMessage='Add to cart'
        />
      </ButtonText>

      <Overline element='p'>
        <FormattedMessage
          id='check-ups.add-items'
          defaultMessage='{count, plural, 
              =0 {No items added}
              one {# item added}
              other {# items added}
            }'
          values={{ count: selectedProducts.length }}
        />
      </Overline>
    </a>
  );
};

CheckupAccordionAddToCartButton.propTypes = {
  selectedProducts: PropTypes.array,
  numberOfItems: PropTypes.number
};

export default CheckupAccordionAddToCartButton;
