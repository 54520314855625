import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import ListItemText from '../ListItemText';
import ListItemTextWithLink from '../ListItemTextWithLink';

import { Subtitle1, Subtitle2 } from '../Typography';
import { ReactComponent as WarningIcon } from '../../assets/svg/important-red.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svg/success-icon.svg';

import styles from './index.module.scss';

const iconMap: Record<
  string,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  warning: WarningIcon,
  success: SuccessIcon
};

enum TextType {
  TEXT = 'text',
  TEXT_WITH_LINK = 'textWithLink'
}

export interface ListItem {
  type: TextType;
  text: string;
  link: string;
  linkText: string;
}

export interface BannerWithListProps {
  title: string;
  description?: string;
  iconType: string;
  list: ListItem[];
  className?: string;
}

const BannerWithList: React.FC<BannerWithListProps> = ({
  title,
  description,
  iconType,
  list,
  className
}) => {
  const Icon = iconMap[iconType];

  return (
    <div className={cx({ [styles.wrapper]: true }, className)}>
      <div className={styles.header}>
        <Subtitle2 className={styles.title}>
          <FormattedMessage id={title} />
        </Subtitle2>
        {description && (
          <Subtitle1 className={styles.description}>
            <FormattedMessage id={description} />
          </Subtitle1>
        )}
      </div>
      <div className={styles.list}>
        {list.map(item => {
          if (item.type === TextType.TEXT_WITH_LINK) {
            return (
              <ListItemTextWithLink
                key={item.text}
                text={item.text}
                icon={<Icon />}
                link={item.link}
                linkText={item.linkText}
              />
            );
          }

          if (item.type === TextType.TEXT) {
            return (
              <ListItemText key={item.text} text={item.text} icon={<Icon />} />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default BannerWithList;
