import StiCheckHero from '../../../assets/images/sti-check-hero-section.png';
import Female360StdImage from '../../../assets/images/female-360-std.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'sti-screening-female',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.sti-screening-female.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: StiCheckHero,
      text: 'learnMoreContent.sti-screening-female.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-female.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem6', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.riskGroups.listItem7', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-female.whatToCheck.title',
      description:
        'learnMoreContent.sti-screening-female.whatToCheck.description',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.sti-screening-female.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.whatToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.whatToCheck.listItem3', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.whatToCheck.listItem4', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.whatToCheck.listItem5', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.sti-screening-female.frequency.title',
      description: { text: 'learnMoreContent.sti-screening-female.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.sti-screening-female.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.sti-screening-female.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.howToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.howToCheck.listItem3', type: 'textWithLink', link: 'https://gratis-hiv-sti-test.ch/', linkText: 'learnMoreContent.sti-screening-male.howToCheck.pilotProject' },
      ],
      product: {
        name:
          'learnMoreContent.sti-screening-female.howToCheck.product.std360women.name',
        description:
          'learnMoreContent.sti-screening-female.howToCheck.product.std360women.description',
        type: 'std360women',
        price: 'CHF 289',
        tags:
          'learnMoreContent.sti-screening-female.howToCheck.product.std360women.tags',
        link:
          'https://yourself.health/products/std-test-for-women-from-home-hiv-syphilis-hepatitis-chlamydia-gonorrhea',
        image: Female360StdImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39872699105366:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-female.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem4', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem5', type: 'text' },
        { text: 'learnMoreContent.sti-screening-female.preventiveMeasures.listItem6', type: 'text' },
      ]
    }
  ]
}