import React from 'react';
import InnerLink from '../InnerLink';
import OuterLink from '../OuterLink';
import extractPathFromUrl from '../../app/helpers/extractPathFromUrl.js';

const HEALTH_YOURSELF_URL = process.env.REACT_APP_SECURE_APP_URL;

export enum LinkVariants {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
  ELEVATED = 'elevated',
  TONAL = 'tonal',
  OUTLINED_GRADIENT = 'outlinedGradient'
}

interface LinkProps {
  url: string;
  variant?:
    | 'primary'
    | 'outlined'
    | 'text'
    | 'elevated'
    | 'tonal'
    | 'outlinedGradient';
  size?: 'small' | 'normal';
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const Link: React.FC<LinkProps> = ({
  url,
  variant,
  size,
  children,
  className,
  disabled
}) => {
  const isInnerUrl =
    (HEALTH_YOURSELF_URL && url.includes(HEALTH_YOURSELF_URL)) ||
    url.startsWith('/');

  const path = extractPathFromUrl(url);

  return (
    <>
      {isInnerUrl ? (
        <InnerLink
          to={path}
          variant={variant}
          size={size}
          className={className}
          disabled={disabled}
        >
          {children}
        </InnerLink>
      ) : (
        <OuterLink
          href={url}
          variant={variant}
          size={size}
          className={className}
          disabled={disabled}
        >
          {children}
        </OuterLink>
      )}
    </>
  );
};

export default Link;
