import CardiovascularHealthCheckHero from '../../../assets/images/cardiovascular-health-check-hero-section.png';
import HealthCheckBasicImage from '../../../assets/images/health-check-basic.png';

import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'cardiovascular-risk-check',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.cardiovascular-risk-check.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: CardiovascularHealthCheckHero,
      text: 'learnMoreContent.cardiovascular-risk-check.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.cardiovascular-risk-check.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem6', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem7', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem8', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.riskGroups.listItem9', type: 'text' }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.listItem1', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.listItem2', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.listItem3', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.listItem4', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.whatToCheck.listItem5', type: "text" }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.cardiovascular-risk-check.frequency.title',
      description:
        { text: 'learnMoreContent.cardiovascular-risk-check.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.cardiovascular-risk-check.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.cardiovascular-risk-check.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.cardiovascular-risk-check.howToCheck.listItem2', type: 'text' },
      ],
      product: {
        name:
          'learnMoreContent.cardiovascular-risk-check.howToCheck.product.healthCheckBasic.name',
        description:
          'learnMoreContent.cardiovascular-risk-check.howToCheck.product.healthCheckBasic.description',
        type: 'healthCheckBasic',
        price: 'CHF 219',
        tags:
          'learnMoreContent.cardiovascular-risk-check.howToCheck.product.healthCheckBasic.tags',
        link:
          'https://yourself.health/products/annual-health-check-from-home-19-health-parameters',
        image: HealthCheckBasicImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39853468188758:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title:
        'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem1', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem2', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem3', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem4', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem5', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem6', type: "text" },
        { text: 'learnMoreContent.cardiovascular-risk-check.preventiveMeasures.listItem7', type: "text" }
      ]
    }
  ]
}
