import React from 'react';
import { FormattedMessage } from 'react-intl';

import BannerItemTextWithLink from '../BannerItemTextWithLink';
import BannerItemText from '../BannerItemText';

import { Heading4 } from '../Typography';

import styles from './index.module.scss';

enum DescriptionTextType {
  TEXT = 'text',
  TEXT_WITH_LINK = 'textWithLink'
}

interface DescriptionItem {
  type: DescriptionTextType;
  text: string;
  link: string;
  linkText: string;
}

export interface BannerWithTextAndDescriptionProps {
  title: string;
  description: DescriptionItem;
}

const BannerWithTextAndDescription: React.FC<BannerWithTextAndDescriptionProps> = ({
  title,
  description
}) => {
  return (
    <div className={styles.wrapper}>
      <Heading4 className={styles.title}>
        <FormattedMessage id={title} />
      </Heading4>

      {description.type === DescriptionTextType.TEXT_WITH_LINK ? (
        <BannerItemTextWithLink
          description={description.text}
          link={description.link}
          linkText={description.linkText}
        />
      ) : (
        <BannerItemText text={description.text} />
      )}
    </div>
  );
};

export default BannerWithTextAndDescription;
