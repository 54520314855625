import ColonCancerHero from '../../../assets/images/colon-cancer-screening-hero-section.png';
import ColonCancerImage from '../../../assets/images/colon-cancer-screening.png';
import POST_COMPONENT_TYPE from '../postComponentType';
export default {
  key: 'colon-cancer-screening',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.colon-cancer-screening.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: ColonCancerHero,
      text: 'learnMoreContent.colon-cancer-screening.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.colon-cancer-screening.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem6', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.riskGroups.listItem7', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.colon-cancer-screening.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.colon-cancer-screening.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.whatToCheck.listItem2', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.colon-cancer-screening.frequency.title',
      description:
        { text: 'learnMoreContent.colon-cancer-screening.frequency.description', type: 'textWithLink', link: 'https://www.swisscancerscreening.ch/de/angebote-in-ihrem-kanton', linkText: 'learnMoreContent.colon-cancer-screening.frequency.earlyDetectionProgram' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.colon-cancer-screening.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.colon-cancer-screening.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.howToCheck.listItem2', type: 'text' },
      ],
      product: {
        name:
          'learnMoreContent.colon-cancer-screening.howToCheck.product.colonCancerScreening.name',
        description:
          'learnMoreContent.colon-cancer-screening.howToCheck.product.colonCancerScreening.description',
        type: 'colonCancerScreening',
        price: 'CHF 99',
        tags:
          'learnMoreContent.colon-cancer-screening.howToCheck.product.colonCancerScreening.tags',
        link:
          'https://yourself.health/products/colon-cancer-screening-from-home-swiss-lab',
        image: ColonCancerImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/44355608215853:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem4', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem5', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem6', type: 'text' },
        { text: 'learnMoreContent.colon-cancer-screening.preventiveMeasures.listItem7', type: 'text' },
      ]
    }
  ]
}