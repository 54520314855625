import React from 'react';
import PropTypes from 'prop-types';

import { Body2, Subtitle2 } from '../../../../components/Typography';
import { ReactComponent as ArrowIcon } from '../../../../assets/svg/arrow-right-up.svg';

import './index.scss';

const CheckupAccordionSmallBanner = ({ data }) => {
  const { link, text, description, image } = data;
  return (
    <a
      href={link}
      className='checkup-accordion-small-banner'
      target='_blank'
      rel='noopener noreferrer'
    >
      <span className='checkup-accordion-small-banner__content'>
        <img
          className='checkup-accordion-small-banner__image'
          src={image}
          alt={text}
        />
        <span className='checkup-accordion-small-banner__text-wrapper'>
          <span className='checkup-accordion-small-banner__text-and-icon-wrapper'>
            <Subtitle2>{text}</Subtitle2>
            <ArrowIcon />
          </span>
          <Body2>{description}</Body2>
        </span>
      </span>
    </a>
  );
};

CheckupAccordionSmallBanner.propTypes = {
  data: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
  })
};

export default CheckupAccordionSmallBanner;
