import React from 'react';
import Divider from '../../../components/Divider';
import HealthKnowledgeCard from './HealthKnowledgeCard';
import { FormattedMessage, useIntl } from 'react-intl';

import './index.scss';

const MyHealthHealthKnowledge = () => {
  const intl = useIntl();
  const annualHealthChecksFromHomeUrl = intl.formatMessage({
    id: 'my-health.annual-health-checks-from-home-url'
  });

  return (
    <div className='my-health-health-knowledge'>
      <div className='my-health-health-knowledge__content-wrapper'>
        <Divider
          className='my-health-health-knowledge__divider'
          lineClassName='my-health-health-knowledge__divider-line'
          title={
            <FormattedMessage id='my-health.current' defaultMessage='Current' />
          }
        />
        <a
          className='my-health-health-knowledge__content'
          href={annualHealthChecksFromHomeUrl}
          target='_blank'
          rel='noreferrer'
        >
          <HealthKnowledgeCard
            eyebrow={
              <FormattedMessage
                id='my-health.check-up-from-home'
                defaultMessage='Check-up from home'
              />
            }
            text={
              <FormattedMessage
                id='my-health.check-for-commong-health-risks'
                defaultMessage='Check for common health risks from home'
              />
            }
            className='my-health-health-knowledge__card-background'
          />
        </a>
      </div>
    </div>
  );
};

export default MyHealthHealthKnowledge;
