import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Body2, Subtitle1 } from '../Typography';
import RegularButton from '../../app/components/RegularButton';
import LearnMoreButton from '../../app/components/LearnMoreButton';
import CopyToClipboardButton from '../CopyToClipboardButton';

import styles from './index.module.scss';

export type ProductCardWithDescription = {
  title: string;
  testType: string;
  price: string;
  description: string;
  iconUrl: string;
  copyableCode: string;
  cta: { url: string; text: string; type: string; disabled: boolean };
};

export interface OrderProductCardWithDescriptionProps {
  productCard: ProductCardWithDescription;
  onClick: () => void;
}

const OrderProductCardWithDescription: React.FC<OrderProductCardWithDescriptionProps> = ({
  productCard,
  onClick
}) => {
  return (
    <div className={styles.wrapper}>
      {productCard.iconUrl && (
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={productCard.iconUrl}
            alt={productCard.title}
          />
        </div>
      )}
      <div className={styles.rightSide}>
        <Subtitle1>{productCard.title}</Subtitle1>

        <Body2 className={styles.text}>
          {productCard.price && <s>CHF {productCard.price}</s>}
        </Body2>

        <Markdown
          components={{
            p: ({ node, ...props }) => (
              <Body2 className={styles.description} {...props} />
            )
          }}
          rehypePlugins={[rehypeRaw as any]}
        >
          {productCard.description}
        </Markdown>

        <div className={styles.buttonsWrapper}>
          {/* TODO: Refactor this */}
          {productCard.cta && productCard.cta.type === 'link' && (
            <LearnMoreButton cta={productCard.cta} />
          )}
          {productCard.cta && productCard.cta.type !== 'link' && (
            <RegularButton
              onClick={onClick}
              disabled={productCard.cta.disabled}
            >
              {productCard.cta.text}
            </RegularButton>
          )}
          {/* Example of use of this is Klenico employee benefit accordion */}
          {productCard.copyableCode && (
            <CopyToClipboardButton
              value={productCard.copyableCode}
              className={styles.copyButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderProductCardWithDescription;
