import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonVariants } from '../../../components/Button';

const RegularButton = ({ children, onClick, disabled }) => {
  return (
    <Button
      onClick={onClick}
      size='small'
      disabled={disabled}
      variant={ButtonVariants.ELEVATED}
    >
      {children}
    </Button>
  );
};

RegularButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default RegularButton;
