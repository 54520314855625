import React from 'react';
import BannerItemText from '../BannerItemText';

import styles from './index.module.scss';

interface BannerWithTextBoxProps {
  image: string;
  text: string;
}

const BannerWithTextBox: React.FC<BannerWithTextBoxProps> = ({
  image,
  text
}) => {
  return (
    <div className={styles.wrapper}>
      <img src={image} alt='Banner' className={styles.image} />
      <div className={styles.textBox}>
        <BannerItemText text={text} />
      </div>
    </div>
  );
};

export default BannerWithTextBox;
