import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { authHeader } from '../../utils/helpers';
import { FormattedMessage } from 'react-intl';

import { submitAnswer } from '../../actions/data';

import { Heading5 } from '../../../components/Typography';

import './index.scss';

const IFRAME_CONFIG_IDS = {
  fertilityConsultation: '84655239',
  stdConsultation: '84654621'
};

const IFRAME_WRAPPER_ID = 'medicosearchWidget';
const MEDICOSEARCH_SCRIPT_SRC = 'https://www.medicosearch.ch/widget/api/js?autoInit=false';
const MEDICOSEARCH_TOKEN = '449632a9-a2cf-4dda-ac6d-d457f4d84096';

const BookingIframe = ({
  data,
  dataLoading,
  language,
  dispatch,
  conversationFlowStateKey,
  conversationId,
  userData,
  testCode
}) => {
  const [iframeLoading, setIframeLoading] = useState(true);
  const [scriptError, setScriptError] = useState(false);
  const containerRef = useRef(null);
  const { info } = data;
  const { userId } = userData || {};

  const initWidget = useCallback(() => {
    if (window.MedicosearchBookingWidget2) {
      new window.MedicosearchBookingWidget2({
        wrapper: `#${IFRAME_WRAPPER_ID}`,
        token: MEDICOSEARCH_TOKEN,
        configId: IFRAME_CONFIG_IDS[info.type],
        lang: language,
        onBookingDone: function(bookingData) {
          const booking = bookingData.bookings[0];
          const bookingId = booking.id;
          const startDate = booking.startDate;
          
          // Send booking data to backend
          fetch(`${process.env.REACT_APP_API_URL}/test-results/${testCode}/consultation-booking`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...authHeader()
            },
            body: JSON.stringify({
              consultationBookingId: bookingId,
              bookedConsultationDate: startDate
            }),
          })
          .then(() => {
            const answerData = {
              action: 'next',
              conversationFlowStateKey: conversationFlowStateKey,
              conversationId: conversationId,
              userId: userId
            };

            dispatch(submitAnswer(answerData));
          })
          .catch(error => {
            // TODO: handle error
            console.error('Error sending booking data:', error);
          });
        },
      });

      setIframeLoading(false);
    }
  }, [info.type, language, testCode, conversationFlowStateKey, conversationId, userId, dispatch]);

  useEffect(() => {
    setIframeLoading(true);
    setScriptError(false);
    if (!info.type || !IFRAME_CONFIG_IDS[info.type] || !userData) return;

    const scriptSrc = MEDICOSEARCH_SCRIPT_SRC;
    let script = document.querySelector(`script[src^="${scriptSrc}"]`);

    if (!script) {
      script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;

      script.onload = () => initWidget();
      script.onerror = () => {
        setIframeLoading(false);
        setScriptError(true);
      };

      document.body.appendChild(script);
    } else {
      initWidget();
    }

    return () => {
      const widgetContainer = document.getElementById(IFRAME_WRAPPER_ID);
      if (widgetContainer) {
        widgetContainer.innerHTML = '';
      }
    };
  }, [language, info.type, initWidget, userData]);

  return (
    <div className='booking-iframe-container'>
      <div
        className={cx('booking-iframe', {
          'booking-iframe--loading-iframe': iframeLoading
        })}
      >
        <div id={IFRAME_WRAPPER_ID} ref={containerRef}></div>

        {(dataLoading || iframeLoading) && (
          <div className='booking-iframe__loading-overlay'>
            <div className='booking-iframe__loader'></div>
          </div>
        )}

        {scriptError && (
          <Heading5 className='booking-iframe__error-message'>
            <FormattedMessage
              id='test-result.try-again-later'
              defaultMessage='Something went wrong. Please try again later.'
            />
          </Heading5>
        )}
      </div>
    </div>
  );
};

BookingIframe.propTypes = {
  dispatch: PropTypes.func,
  conversationFlowStateKey: PropTypes.string,
  conversationId: PropTypes.string,
  userData: PropTypes.object,
  dataLoading: PropTypes.bool,
  data: PropTypes.shape({
    info: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  language: PropTypes.string,
  testCode: PropTypes.string
};

export default BookingIframe;
