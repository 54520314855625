import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';

import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
  ELEVATED = 'elevated',
  TONAL = 'tonal',
  OUTLINED_GRADIENT = 'outlinedGradient'
}

export type ButtonProps = {
  type?: 'submit' | 'button';
  variant?: ButtonVariants;
  size?: 'small' | 'normal';
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLButtonElement>;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  size = 'normal',
  children,
  onClick,
  disabled,
  innerRef,
  className
}) => {
  const buttonClassName = classNames(
    styles.normal,
    styles[size],
    styles[variant]
  );

  const isOutlinedGradient = variant === ButtonVariants.OUTLINED_GRADIENT;
  const isSizeSmall = size === 'small';

  return (
    <button
      className={cx(
        {
          [buttonClassName]: true
        },
        className && { [className]: true }
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
      ref={innerRef}
    >
      {isOutlinedGradient ? (
        <span
          className={cx(styles.gradientContent, {
            [styles.smallGradientContent]: isSizeSmall
          })}
        >
          <span className={styles.gradientText}>{children}</span>
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
