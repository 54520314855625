import React from 'react';
import cx from 'classnames';
import { Body2 } from '../Typography';
import Button, { ButtonVariants } from '../Button';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

interface TextWithInfoButtonProps {
  text: string;
  info: boolean;
  handleInfo: () => void;
  className?: string;
}

const TextWithInfoButton: React.FC<TextWithInfoButtonProps> = ({
  text,
  info,
  handleInfo,
  className
}) => {
  return (
    <div className={cx({ [styles.wrapper]: true }, className)}>
      <Body2 className={styles.text}>{text}</Body2>
      {info && (
        <div className={styles.buttonWrapper}>
          <Button
            variant={ButtonVariants.ELEVATED}
            className={styles.button}
            size='small'
            onClick={handleInfo}
          >
            <FormattedMessage
              id='test-result.learn-more'
              defaultMessage='Learn more'
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TextWithInfoButton;
