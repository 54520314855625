import GyneacologyCheckupHero from '../../../assets/images/gynaecology-check-up-hero-section.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'gynaecology-check-up',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.gynaecology-checkup.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: GyneacologyCheckupHero,
      text: 'learnMoreContent.gynaecology-checkup.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.gynaecology-checkup.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem5', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.riskGroups.listItem6', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.gynaecology-checkup.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.gynaecology-checkup.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.whatToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.whatToCheck.listItem3', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.whatToCheck.listItem4', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.gynaecology-checkup.frequency.title',
      description: { text: 'learnMoreContent.gynaecology-checkup.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.gynaecology-checkup.howToCheck.title',
      iconType: 'success',
      list: [{ text: 'learnMoreContent.gynaecology-checkup.howToCheck.listItem1', type: 'text' }]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.gynaecology-checkup.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.gynaecology-checkup.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.gynaecology-checkup.preventiveMeasures.listItem3', type: 'text' },
      ]
    }
  ]
}