import React from 'react';
import cx from 'classnames';
import { Body2, Caption, Subtitle1, Subtitle2 } from '../Typography';
import TestResultsProgressBar, { Range } from '../TestResultsProgressBar';

import { ReactComponent as InfoGradientIcon } from '../../assets/svg/info-gradient-icon.svg';
import { ReactComponent as ListBulletedIcon } from '../../assets/storybook-placeholders/svg/format-list-bulleted.svg';
import { ReactComponent as KeyboardArrowRightIcon } from '../../assets/storybook-placeholders/svg/keyboard-arrow-right.svg';

import styles from './index.module.scss';

enum ValueType {
  STRING = 'string',
  NUMBER = 'number'
}

export interface ParameterResultCardProps {
  valueType: ValueType;
  unit?: string;
  title: string;
  text: string;
  stringValue: string;
  numberValue: number;
  resultBackgroundColor: string;
  handleInfo?: () => void;
  buttonText?: string;
  previousResults?: boolean;
  openPreviousResults?: () => void;
  ranges: Range[];
  info?: string;
  className: string;
}

const ParameterResultCard: React.FC<ParameterResultCardProps> = ({
  valueType,
  unit,
  title,
  text,
  stringValue,
  numberValue,
  resultBackgroundColor,
  handleInfo,
  info,
  buttonText,
  previousResults,
  openPreviousResults,
  ranges,
  className
}) => {
  const renderValue = () => {
    if (valueType === ValueType.STRING) {
      return stringValue;
    } else {
      return numberValue + ' ' + unit;
    }
  };

  const progressBarStyles = previousResults
    ? styles.progressBarWithResults
    : styles.progressBarWithoutResults;

  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true,
          [styles.wrapperWithPreviousResults]: previousResults
        },
        className && className
      )}
    >
      <div className={styles.titleAndResultWrapper}>
        <div className={styles.titleWrapper}>
          <Subtitle1 className={styles.title}>{title}</Subtitle1>
          {info && (
            <button className={styles.icon} onClick={handleInfo}>
              <InfoGradientIcon />
            </button>
          )}
        </div>
        <div
          className={styles.resultTextWrapper}
          style={{ background: resultBackgroundColor }}
        >
          <Subtitle2 className={styles.resultText}>{renderValue()}</Subtitle2>
        </div>
      </div>
      <Body2 className={cx({ [styles.text]: previousResults })}>{text}</Body2>
      {ranges && ranges.length > 0 && (
        <TestResultsProgressBar
          className={progressBarStyles}
          numberValue={valueType === ValueType.NUMBER ? numberValue : 0}
          ranges={ranges}
        />
      )}

      {previousResults && (
        <button className={styles.buttonWrapper} onClick={openPreviousResults}>
          <div className={styles.buttonTextWrapper}>
            <ListBulletedIcon />
            <Caption className={styles.buttonText} element='p'>
              {buttonText}
            </Caption>
          </div>
          <KeyboardArrowRightIcon />
        </button>
      )}
    </div>
  );
};

export default ParameterResultCard;
