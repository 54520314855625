import { Map } from 'immutable';

import {
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  UPDATE_DASHBOARD_DATA_START,
  UPDATE_DASHBOARD_DATA_SUCCESS,
  UPDATE_DASHBOARD_DATA_ERROR,
  CREATE_TEST_ORDER_START,
  CREATE_TEST_ORDER_SUCCESS,
  CREATE_TEST_ORDER_ERROR,
  RESET_TEST_ORDER_DATA,
  SAVE_CHECKUP_RECOMMENDATION_ID_START,
  SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS,
  SAVE_CHECKUP_RECOMMENDATION_ID_ERROR,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS,
  TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR,
  RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS
} from '../actions/dashboard';

const initialState = Map({
  loading: false,
  error: null,
  data: null,
  orderData: false,
  orderLoading: false,
  orderError: null,
  markAsCompleteButtonLoading: false,
  markAsCompleteButtonSuccess: false
});

const actionsMap = {
  [GET_DASHBOARD_DATA_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [GET_DASHBOARD_DATA_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [GET_DASHBOARD_DATA_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [UPDATE_DASHBOARD_DATA_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error
      })
    );
  },
  [CREATE_TEST_ORDER_START]: state => {
    return state.merge(
      Map({
        orderLoading: true,
        orderError: null,
        orderData: false
      })
    );
  },
  [CREATE_TEST_ORDER_SUCCESS]: (state, action) => {
    const currentData = state.get('data');
    const { employeeBenefits } = currentData;
    const newData = {
      ...currentData,
      employeeBenefits: {
        ...employeeBenefits,
        benefitTests: action.data
      }
    };
    return state.merge(
      Map({
        orderLoading: false,
        orderError: null,
        data: newData,
        orderData: true
      })
    );
  },
  [CREATE_TEST_ORDER_ERROR]: (state, action) => {
    return state.merge(
      Map({
        orderLoading: false,
        orderError: action.error,
        orderData: false
      })
    );
  },
  [RESET_TEST_ORDER_DATA]: state => {
    return state.merge(
      Map({
        orderLoading: false,
        orderError: null,
        orderData: false
      })
    );
  },
  [SAVE_CHECKUP_RECOMMENDATION_ID_START]: state => {
    return state.merge(
      Map({
        loading: true,
        error: null
      })
    );
  },
  [SAVE_CHECKUP_RECOMMENDATION_ID_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: null,
        data: action.data
      })
    );
  },
  [SAVE_CHECKUP_RECOMMENDATION_ID_ERROR]: (state, action) => {
    return state.merge(
      Map({
        loading: false,
        error: action.error,
        data: false
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_START]: state => {
    return state.merge(
      Map({
        markAsCompleteButtonLoading: true,
        markAsCompleteButtonSuccess: false,
        error: null
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_SUCCESS]: (state, action) => {
    const { key, completed } = action.data;
    const currentData = state.get('data');
    const { checkUpRecommendations } = currentData;
    const updatedCards = checkUpRecommendations.checkUpCards.cards.map(card => {
      if (key === card.key) {
        return { ...card, completed: completed };
      }

      return card;
    });

    currentData.checkUpRecommendations.checkUpCards.cards = updatedCards;

    return state.merge(
      Map({
        markAsCompleteButtonLoading: false,
        data: currentData,
        markAsCompleteButtonSuccess: true,
        error: null
      })
    );
  },
  [TOGGLE_CHECKUP_CARD_COMPLETION_STATUS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        markAsCompleteButtonLoading: false,
        markAsCompleteButtonSuccess: false,
        error: action.error
      })
    );
  },
  [RESET_MARK_AS_COMPLETE_BUTTON_SUCCESS]: state => {
    return state.merge(
      Map({
        markAsCompleteButtonSuccess: false
      })
    );
  }
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
