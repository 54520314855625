import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';

const BasicContainer = ({ children, lang, changeLanguage }) => {
  return (
    <div className='basic-container'>
      <Header
        searchParams={{}}
        conversationFlowKey={null}
        restartConversationFlowKey={null}
        flowCountry={'switzerland'}
        changeLanguage={changeLanguage}
        language={lang}
        availableLanguages={['en', 'de', 'fr']}
        headerNewDesign={true}
      />
      {children}
    </div>
  );
};

BasicContainer.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
  changeLanguage: PropTypes.func
};

export default BasicContainer;
