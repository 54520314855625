import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Body2 } from '../Typography';

import styles from './index.module.scss';

interface BannerItemTextWithLinkProps {
  description: string;
  link: string;
  linkText: string;
}

const BannerItemTextWithLink: React.FC<BannerItemTextWithLinkProps> = ({
  description,
  link,
  linkText
}) => {
  return (
    <Body2>
      {
        <FormattedMessage
          id={description}
          values={{
            link: (
              <a
                className={styles.link}
                href={link}
                target='_blank'
                rel='noreferrer'
              >
                <FormattedMessage id={linkText} />
              </a>
            )
          }}
        />
      }
    </Body2>
  );
};

export default BannerItemTextWithLink;
