import React from 'react';
import PropTypes from 'prop-types';

import LearnMorePostTitle from '../LearnMorePostTitle';
import BannerWithTextBox from '../../../components/BannerWithTextBox';
import BannerWithList from '../../../components/BannerWithList';
import BannerWithTextAndDescription from '../../../components/BannerWithTextAndDescription';
import BannerWithListAndProductCard from '../BannerWithListAndProductCard';
import POST_COMPONENT_TYPE from '../../postsContent/postComponentType';

import './index.scss';

const LearnMoreComponentsMapper = {
  [POST_COMPONENT_TYPE.title]: LearnMorePostTitle,
  [POST_COMPONENT_TYPE.bannerWithTextBox]: BannerWithTextBox,
  [POST_COMPONENT_TYPE.bannerWithList]: BannerWithList,
  [POST_COMPONENT_TYPE.bannerWithTextAndDescription]: BannerWithTextAndDescription,
  [POST_COMPONENT_TYPE.bannerWithListAndProductCard]: BannerWithListAndProductCard
};

const LearnMorePageGenerator = ({ data }) => {
  return (
    <>
      {data.sectionList.map((section, key) => {
        const Component = LearnMoreComponentsMapper[section.type];
        return (
          <div
            key={`${key}-${section.type}`}
            className={`learn-more-page-generator__${section.type}`}
          >
            <Component {...section} />
          </div>
        );
      })}
    </>
  );
};

LearnMorePageGenerator.propTypes = {
  data: PropTypes.object
};

export default LearnMorePageGenerator;
