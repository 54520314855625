import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from '../../../assets/svg/important-icon.svg';

import './index.scss';
import { Subtitle2 } from '../../../components/Typography';

const iconMapper = {
  error: WarningIcon
  // We will add more mappings here as needed
};

const CheckupBanner = ({ type, text }) => {
  const IconComponent = iconMapper[type] || null;

  return (
    <div className='checkup-banner'>
      <div className='checkup-banner__icon'>
        <IconComponent />
      </div>
      <Subtitle2 className='checkup-banner__text'>{text}</Subtitle2>
    </div>
  );
};

CheckupBanner.propTypes = { type: PropTypes.string, text: PropTypes.string };

export default CheckupBanner;
