import StiCheckHero from '../../../assets/images/sti-check-hero-section.png';
import Male360StdImage from '../../../assets/images/male-360-std.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'sti-screening-male',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.sti-screening-male.name'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: StiCheckHero,
      text: 'learnMoreContent.sti-screening-male.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-male.riskGroups.title',
      iconType: 'warning',
      list: [
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem1',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem2',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem3',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem4',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem5',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem6',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.riskGroups.listItem7',
          type: 'text'
        }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-male.whatToCheck.title',
      description:
        'learnMoreContent.sti-screening-male.whatToCheck.description',
      iconType: 'success',
      list: [
        {
          text: 'learnMoreContent.sti-screening-male.whatToCheck.listItem1',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.whatToCheck.listItem2',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.whatToCheck.listItem3',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.whatToCheck.listItem4',
          type: 'text'
        },
        {
          text: 'learnMoreContent.sti-screening-male.whatToCheck.listItem5',
          type: 'text'
        }
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.sti-screening-male.frequency.title',
      description: { text: 'learnMoreContent.sti-screening-male.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.sti-screening-male.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.sti-screening-male.howToCheck.listItem1', type: 'text' },

        { text: 'learnMoreContent.sti-screening-male.howToCheck.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.howToCheck.listItem3', type: 'textWithLink', link: 'https://gratis-hiv-sti-test.ch/', linkText: 'learnMoreContent.sti-screening-male.howToCheck.pilotProject' },
      ],
      product: {
        name:
          'learnMoreContent.sti-screening-male.howToCheck.product.std360men.name',
        description:
          'learnMoreContent.sti-screening-male.howToCheck.product.std360men.description',
        type: 'std360men',
        price: 'CHF 289',
        tags:
          'learnMoreContent.sti-screening-male.howToCheck.product.std360men.tags',
        link:
          'https://yourself.health/products/std-test-for-men-from-home-hiv-syphilis-hepatitis-chlamydia-gonorrhea',
        image: Male360StdImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39890858442838:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.sti-screening-male.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem4', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem5', type: 'text' },
        { text: 'learnMoreContent.sti-screening-male.preventiveMeasures.listItem6', type: 'text' },
      ]
    }
  ]
}