import DiabetesCheckHero from '../../../assets/images/diabetes-check-hero-section.png';
import HealthCheckBasicImage from '../../../assets/images/health-check-basic.png';
import POST_COMPONENT_TYPE from '../postComponentType';

export default {
  key: 'diabetes-screening',
  sectionList: [
    {
      type: POST_COMPONENT_TYPE.title,
      text: 'learnMoreContent.diabetes-screening.name',
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextBox,
      image: DiabetesCheckHero,
      text: 'learnMoreContent.diabetes-screening.banner.text'
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.diabetes-screening.riskGroups.title',
      iconType: 'warning',
      list: [
        { text: 'learnMoreContent.diabetes-screening.riskGroups.listItem1', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.riskGroups.listItem2', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.riskGroups.listItem3', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.riskGroups.listItem4', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.riskGroups.listItem5', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.diabetes-screening.whatToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.diabetes-screening.whatToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.whatToCheck.listItem2', type: 'text' },
      ]
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithTextAndDescription,
      title: 'learnMoreContent.diabetes-screening.frequency.title',
      description: { text: 'learnMoreContent.diabetes-screening.frequency.description', type: 'text' }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithListAndProductCard,
      title: 'learnMoreContent.diabetes-screening.howToCheck.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.diabetes-screening.howToCheck.listItem1', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.howToCheck.listItem2', type: 'text' },
      ],
      product: {
        name:
          'learnMoreContent.diabetes-screening.howToCheck.product.healthCheckBasic.name',
        description:
          'learnMoreContent.diabetes-screening.howToCheck.product.healthCheckBasic.description',
        type: 'healthCheckBasic',
        price: 'CHF 219',
        tags:
          'learnMoreContent.diabetes-screening.howToCheck.product.healthCheckBasic.tags',
        link:
          'https://yourself.health/products/annual-health-check-from-home-19-health-parameters',
        image: HealthCheckBasicImage,
        addToCartUrl:
          'https://hy-demo.myshopify.com/cart/39853468188758:1?storefront=true'
      }
    },
    {
      type: POST_COMPONENT_TYPE.bannerWithList,
      title: 'learnMoreContent.diabetes-screening.preventiveMeasures.title',
      iconType: 'success',
      list: [
        { text: 'learnMoreContent.diabetes-screening.preventiveMeasures.listItem1', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.preventiveMeasures.listItem2', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.preventiveMeasures.listItem3', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.preventiveMeasures.listItem4', type: 'text' },
        { text: 'learnMoreContent.diabetes-screening.preventiveMeasures.listItem5', type: 'text' },
      ]
    }
  ]
}