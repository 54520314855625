import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDownIcon } from '../../../assets/svg/arrow-down-icon.svg';
import { Subtitle1, Body2, Overline } from '../../../components/Typography';

import './index.scss';

const CheckupAccordion = ({
  number,
  title,
  subtitle,
  children,
  isOpen,
  handleOpenAccordion
}) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current && isOpen) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen, children]);

  return (
    <div className='checkup-accordion'>
      <div className='checkup-accordion__header' onClick={handleOpenAccordion}>
        <div className='checkup-accordion__header-content'>
          <Overline className='checkup-accordion__header-number'>
            {number}
          </Overline>
          <div className='checkup-accordion__header-text-wrapper'>
            <div className='checkup-accordion__header-title-and-arrow-wrapper'>
              <Subtitle1 className='checkup-accordion__header-title'>
                {title}
              </Subtitle1>
              <ArrowDownIcon
                className={cx({
                  'checkup-accordion__arrow-down': true,
                  'checkup-accordion__arrow-up': isOpen
                })}
              />
            </div>
            <Body2 className='checkup-accordion__header-subtitle'>
              {subtitle}
            </Body2>
          </div>
        </div>
      </div>
      {/* TODO: Marko - why inline style? */}
      <div
        ref={contentRef}
        className={cx('checkup-accordion__content', {
          'checkup-accordion__content--open': isOpen
        })}
        style={{
          maxHeight: `${contentHeight}px`,
          transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
          opacity: isOpen ? 1 : 0
        }}
      >
        {children}
      </div>
    </div>
  );
};

CheckupAccordion.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  handleOpenAccordion: PropTypes.func
};

export default CheckupAccordion;
